import { get, map } from "lodash";

import axios from "utils/axios";
import { createSlice } from "@reduxjs/toolkit";

// initial state
export const initialState = {
  error: {},
  senderids: [],
  adminSenderids: [],
  bannedSenderIds: [],
  bannedSenderId: null,
  sentmessages: [],
  schedulemessages: [],
  msgStats: {},
  sendScheduleNow: [],
  groups: [],
  messageData: {
    contactType: "simple",
    type: "",
    message: "",
    senderid: "",
    sendto: [],
    groups: [],
    contactList: [],
    isScheduled: null,
    isRepeat: null
  },
  schedulemessage: null,
  targetId: null
};

const messaging = createSlice({
  name: "messaging",
  initialState,
  reducers: {
    hasError: (state, action) => {
      state.error = action.payload;
    },

    clearError: (state) => {
      state.error = {};
    },

    // !* STATS *! //
    getMessageStatsSuccess: (state, action) => {
      state.msgStats = action.payload;
    },

    // !* SENDER IDs *! //
    getSenderIdsSuccess: (state, action) => {
      state.senderids = action.payload;
    },

    getAdminSenderIdsSuccess: (state, action) => {
      state.adminSenderids = action.payload;
    },

    createSenderIdSuccess: (state, action) => {
      const senderid = get(action, "payload.data.", []);
      state.senderids = senderid;
    },

    deleteSenderId: (state, action) => {
      state.senderids = state.senderids.filter((senderid) => senderid.id !== action.payload);
    },

    updateSenderIdSuccess: (state, action) => {
      const senderid = action.payload;
      const senderids = map(state.senderids, (item) => (item.id === senderid.id ? senderid : item));
      state.senderids = senderids;
    },

    approveSenderIdSuccess: (state, action) => {
      const senderid = action.payload;
      const senderids = map(state.senderids, (item) => (item.id === senderid.id ? senderid : item));
      state.senderids = senderids;
    },

    getBannedSenderIdSuccess: (state, action) => {
      state.bannedSenderId = action.payload;
    },

    getBannedSenderIdsSuccess: (state, action) => {
      state.bannedSenderIds = action.payload;
    },

    addBannedSenderIdSuccess: (state, action) => {
      state.bannedSenderIds = [...state.bannedSenderIds, action.payload];
    },

    deleteBannedSenderIdSuccess: (state, action) => {
      state.bannedSenderIds = state.bannedSenderIds.filter((senderid) => senderid.id !== action.payload);
    },

    // !* SENT MESSAGES *! //
    getSentMessagesSuccess: (state, action) => {
      state.sentmessages = action.payload;
    },

    //!* SHCEDULE MESSAGE

    getSendScheduleNowSuccess: (state, action) => {
      state.sendScheduleNow = action.payload;
    },

    getScheduleMessagesSuccess: (state, action) => {
      state.schedulemessages = action.payload;
    },

    addScheduleMessagesSuccess: (state, action) => {
      state.schedulemessages = action.payload;
    },

    deleteScheduleMessagesSuccess: (state, action) => {
      state.schedulemessages = state.schedulemessages.filter((schedule) => schedule.id !== action.payload);
    },

    getScheduleMessageSuccess: (state, action) => {
      state.schedulemessage = action.payload;
    },

    //!* GROUPS
    setGroupsSuccess(state, action) {
      const groupa = get(action, "payload.data.content", []);
      state.groups = groupa;
    },

    setMessageData(state, action) {
      console.log("🚀 ~ setMessageData ~ action:", action.payload);

      state.messageData = action.payload;
    },

    //targetId
    setTargetId(state, action) {
      state.targetId = action.payload;
    }
  }
});
export default messaging.reducer;

export const {
  clearError,
  setMessageData,
  getMessageStatsSuccess,
  getAdminSenderIdsSuccess,
  getSenderIdsSuccess,
  approveSenderIdSuccess,
  getBannedSenderIdSuccess,
  getBannedSenderIdsSuccess,
  addBannedSenderIdSuccess,
  deleteBannedSenderIdSuccess,
  setGroupsSuccess,
  hasError,
  getSentMessagesSuccess,
  createSenderIdSuccess,
  deleteSenderId,
  updateSenderIdSuccess,
  getSendScheduleNowSuccess,
  getScheduleMessagesSuccess,
  addScheduleMessagesSuccess,
  deleteScheduleMessagesSuccess,
  getScheduleMessageSuccess,
  setTargetId
} = messaging.actions;

// !*STATS*! //
export function getMessageStats(token, clientid, data) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/messaging/${clientid}/statistics`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      dispatch(getMessageStatsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// !*SENDER IDs*! //

/**
 * Permission: ADMIN OR STAFF
 * Retrieves the admin sender IDs from the server.
 * @param {string} token - The authentication token.
 * @returns {Function} - The async function that dispatches the action.
 */
export function getAdminSenderIds(token, page = 0, size = 50, search = "", status = "") {
  return async (dispatch) => {
    try {
      const response = await axios.get("/messaging/senderid", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        },
        params: {
          page,
          search,
          status,
          size,
          sort: "senderid,asc"
        }
      });
      dispatch(getAdminSenderIdsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Permission:ANY
 * Retrieves the sender IDs for a given client ID.
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @returns {Function} - The async function that dispatches the action.
 */
export function getSenderIds(token, clientid, pages = 0, pageSize = 1000, sort = "createdat,desc") {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/messaging/${clientid}/senderid?sort=${sort}`, {
        params: {
          page: pages,
          size: pageSize
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getSenderIdsSuccess(response.data));
    } catch (error) {
      dispatch(messaging.actions.hasError(error));
    }
  };
}

/**
 * Creates a sender ID for messaging.

 * @param {string} token - The authentication token.
 *
 * @param {string} clientid - The client ID.
 * @param {object} data - The data for creating the sender ID.
 * @returns {Function} - The async function that dispatches actions.
 */
export async function createSenderId(token, clientid, data) {
  const response = await axios.post(`/messaging/${clientid}/senderid`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Permission: ANY
 * Updates the sender ID for messaging.
 *
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {string} senderidid - The sender ID ID.
 * @param {object} data - The data to be updated.
 * @returns {Function} - The async dispatch function.
 */
export async function updateSenderId(token, clientid, senderid, data) {
  const response = await axios.put(`/messaging/${clientid}/senderid/${senderid}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

/**
PERMISSION: ADMIN OR STAFF
 * Approves a sender ID.
 *
 * @param {string} token - The authentication token.
 * @param {string} senderidid - The ID of the sender ID to approve.
 * @param {Object} data - The data to send in the request body.
 * @returns {Function} - The async action function.
 */
export async function approveSenderId(token, senderidid, data) {
  const response = await axios.put(`/messaging/senderid/${senderidid}/approveorreject`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Retrieves the banned sender ID using the provided token and sender ID.
 * @param {string} token - The authentication token.
 * @param {string} senderid - The sender ID to retrieve the banned status for.
 * @returns {Function} - An async function that dispatches actions based on the API response.
 */
export function getBannedSenderId(token, senderid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`//messaging/bannedsenderid/${senderid}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getBannedSenderIdSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Retrieves the banned sender IDs from the server.
 * @param {string} token - The authentication token.
 * @returns {Function} - The async function that dispatches the action.
 */
export function getBannedSenderIds(token, page = 0, size = 50, search = "") {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/messaging/bannedsenderid`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        },
        params: {
          sort: "senderid,asc",
          page,
          size,
          search
        }
      });
      dispatch(getBannedSenderIdsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Adds a banned sender ID.
 * @param {string} token - The authentication token.
 * @param {object} data - The data to be sent in the request body.
 * @returns {Function} - The async function that dispatches actions based on the response.
 */
export function addBannedSenderId(token, data) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/messaging/bannedsenderid`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(addBannedSenderIdSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Deletes a banned sender ID.
 * @param {string} token - The authentication token.
 * @param {string} senderid - The ID of the sender to be deleted.
 * @returns {Function} - An async function that dispatches actions based on the result of the delete operation.
 */
export function deleteBannedSenderId(token, senderid) {
  return async (dispatch) => {
    try {
      await axios.delete(`/messaging/bannedsenderid/${senderid}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(deleteBannedSenderIdSuccess(senderid));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// !*SCHEDULE MESSAGE*! //

/**
 * Schedule a message for a client.
 *{
  "senderid": 0,
  "message": "string",
  "type": "TEXT",
  "groups": [
    0
  ],
  "schedule": "string",
  "sendalert": true,
  "alertmsisdn": "string",
  "scheduletype": "REGULAR",
  "repeattype": "NONE",
  "repeattypevalue": "string"
}
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {object} data - The data for the message.
 * @returns {Promise<object>} - A promise that resolves to the response data or rejects with an error.
 */
export function sendScheduleMessage(token, clientid, data) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/messaging/${clientid}/schedule`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      dispatch(addScheduleMessagesSuccess(response.data));
      dispatch(getScheduleMessageSuccess(response.data));
    } catch (error) {
      console.log("🚀 ~ return ~ error:", error);
      return error;
    }
  };
}

/**
 * Retrieves the schedule message for a specific client.
 *
 * @param {string} token - The authentication token.
 * @param {string} clientid - The ID of the client.
 * @returns {Promise} - A promise that resolves to the schedule message data or rejects with an error.
 */
export function getScheduleMessage(token, clientid, page = 0, size = 1000, search = "") {
  return async (dispatch) => {
    const params = {
      page,
      size,
      search,
      sort: "id,desc"
    };
    try {
      const response = await axios.get(`/messaging/${clientid}/schedule`, {
        params,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      dispatch(getScheduleMessagesSuccess(response.data));
    } catch (error) {
      return error;
    }
  };
}

/**
 * Sends a schedule message immediately.
 *
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {string} scheduleid - The schedule ID.
 * @returns {Promise} - A promise that resolves to the response data or rejects with an error.
 */
export async function sendScheduleMessageNow(token, clientid, scheduleid) {
  const response = await axios.get(`/messaging/${clientid}/schedule/${scheduleid}/sendnow`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Deletes a scheduled message.
 *
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {string} scheduleid - The schedule ID.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
export async function deleteScheduledMessage(token, clientid, scheduleid) {
  const response = await axios.delete(`/messaging/${clientid}/schedule/${scheduleid}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

/**
 * Retrieves a scheduled message from the server.
 *
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {string} scheduleid - The schedule ID.
 * @returns {Promise<Object>} - A promise that resolves to the scheduled message data.
 */
export function getScheduledMessage(token, clientid, scheduleid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/messaging/${clientid}/schedule/${scheduleid}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      dispatch(getScheduleMessage(response.data));
    } catch (error) {
      return error;
    }
  };
}

// !*SMS GATEWAY*! //
// ? not sure if this APIs would be consumed

/**
 * Receives an SMPP request and returns a promise that resolves to the response data.
 * @param {string} token - The authentication token.
 * @param {object} data - The data to be sent with the request.
 * @returns {Promise} - A promise that resolves to the response data or rejects with an error.
 */
export function receiveSmppRequest(token, data) {
  return async () => {
    try {
      const response = await axios.post(`/messaging/smpprequestsinput`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;
    } catch (error) {
      return error;
    }
  };
}

/**
 * Receives a delivery response from the messaging service.
 * @param {string} token - The authentication token.
 * @param {object} data - The data to be sent with the request.
 * @returns {Promise<any>} - A promise that resolves to the response data or rejects with an error.
 */
export function receiveDeliveryResponse(token, data) {
  return async () => {
    try {
      const response = await axios.post(`/messaging/smsgatewaycallback`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;
    } catch (error) {
      return error;
    }
  };
}

/**
 * Checks if personalized SMS is available for a given client.
 *
 * @requires ANY
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {object} data - The data to be sent with the request.
 * @param {number} data.senderid - The sender ID.
 * @param {string} data.type - The type of message (e.g., "TEXT").
 * @param {Array} data.destinations - An array of destinations.
 * @param {string} data.destinations[].destination - The destination for the SMS.
 * @param {string} data.destinations[].message - The message content.
 * @param {string} data.schedule - The schedule for sending the SMS.
 * @returns {Promise<any>} - A promise that resolves to the response data or rejects with an error.
 */
export async function checkPersonalizedSMS(token, clientid, data, page = 0, size = 50, search = "") {
  const response = await axios.post(`/messaging/${clientid}/checkpersonalizedsms?page=${page}&size=${size}&numbersearch=${search}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

/**
 * Checks the SMS for a specific client.
 *
 * @param {string} token - The authentication token.
 * @param {string} clientid - The ID of the client.
 * @param {object} data - The data to be sent in the request.
 * @param {number} data.senderid - The sender ID.
 * @param {string} data.message - The message content.
 * @param {string} data.type - The type of message (e.g., "TEXT").
 * @param {Array}  data.destinations - An array of destinations.
 * @param {Array}  data.groups - An array of groups.
 * @param {string} data.schedule - The schedule for sending the SMS.
 * @returns {Promise<object>} - A promise that resolves to the response data or rejects with an error.
 */
export async function checkdSMS(token, clientid, data) {
  const response = await axios.post(`/messaging/${clientid}/checksms`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

/**
 * Sends a personalized SMS to a client.
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {object} data - The data to be sent in the SMS.
 * @returns {Promise} - A promise that resolves to the response data or rejects with an error.
 */
export async function sendPersonalizedSMS(token, clientid, data) {
  const response = await axios.post(`/messaging/${clientid}/sendpersonalizedsms`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

/**
 * Sends an SMS message using the provided token, client ID, and data.
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {object} data - The data to be sent in the SMS message.
 * @returns {Promise<any>} - A promise that resolves to the response data or rejects with an error.
 */
export async function sendSMS(token, clientid, data) {
  const response = await axios.post(`/messaging/${clientid}/sendsms`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

/**
 * Retrieves the sent messages for a specific client.
 *
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
//  * @param {object} data - The data to be sent in the request body.
 * @returns {Promise} A promise that resolves to the response data or rejects with an error.
 */
export async function getSentMessages(token, clientid, data) {
  const params = {
    page: 0,
    size: 500
  };
  // return async (dispatch) => {
  //   try {
  const response = await axios.post(`/messaging/${clientid}/sentmessages?sort=id,desc`, data, {
    params,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
  // dispatch(getSentMessagesSuccess(response.data));
  // } catch (error) {
  //   return error;
  // }
  // };
}

export async function exportSentMessages(token, clientid, data) {
  const response = await axios.post(`/messaging/${clientid}/sentmessages/export`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export function getGroups(token, clientid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/addressbook/${clientid}/group`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(setGroupsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export async function getGroupContacts(token, clientid, groupid) {
  //TODO: Get all contacts
  //  const response = await axios.get(`/addressbook/${clientid}/group/${groupid}/contactlist?paging=false&size=99999999`, {
  const response = await axios.get(`/addressbook/${clientid}/group/${groupid}/allcontacts`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export async function validateContacts(token, data) {
  try {
    const response = await axios.post(`/addressbook/msisdn/validate`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error validating uploaded contacts:", error);
    throw error; // Re-throw the error to be handled by the caller
  }
}
