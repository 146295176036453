// assets
import {
  ArrowCircleDown,
  Edit
  // Trash
  // StopCircle, Trash
} from "iconsax-react";
import { Button, Chip, Dialog, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery } from "@mui/material";
import { EmptyTable, HeaderSort, TableLoading, TablePagination } from "components/third-party/ReactTable";
import { Fragment, useEffect, useMemo, useState } from "react";
import { GlobalFilter, renderFilterTypes } from "utils/react-table";
// material-ui
import { alpha, useTheme } from "@mui/material/styles";
import { dispatch, useSelector } from "store";
// import {
//   find,
//    get } from "lodash";
import { useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";

import IconButton from "components/@extended/IconButton";
import Loader from "components/Loader";
// project-imports
import MainCard from "components/MainCard";
// third-party
import { PopupTransition } from "components/@extended/Transitions";
import PropTypes from "prop-types";
import ScrollX from "components/ScrollX";
import { ThemeMode } from "config";
import { getService } from "store/reducers/options";
// import { openSnackbar } from "store/reducers/snackbar";
import { useServerAdminDestinationPagination } from "hooks/useServerPagination";
import useUserAuthInfo from "hooks/useAuthInfo";
import dayjs from "dayjs";
import {
  // getDestinationList,
  getDestinationNoState
} from "store/reducers/setups";
import useAdminClientId from "hooks/useAdminClientId";
import ClientCTA from "pages/admin/ClientCTA";
import AddDestination from "pages/admin/billing/destinations/AddDestination";
// import AlertDelete from "components/AlertDelete";
import { openSnackbar } from "store/reducers/snackbar";

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, targetId, data, _DATA, renderRowSubComponent, handleAdd }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const filterTypes = useMemo(() => renderFilterTypes, []);
  // const sortBy = { id: "name", desc: false };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // setHiddenColumns,
    visibleColumns,
    page,
    state: { expanded },
    preGlobalFilteredRows
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ["id"] }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  // useEffect(() => {
  //   if (matchDownSM) {
  //     setHiddenColumns(["age", "destination", "visits", "email", "status", "avatar"]);
  //   } else {
  //     setHiddenColumns(["avatar", "email"]);
  //   }
  //   // eslint-disable-next-line
  // }, [matchDownSM]);
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <>
      {/* <TableRowSelection selected={Object.keys(selectedRowIds).length} /> */}
      <Stack spacing={3}>
        <Stack
          direction={matchDownSM ? "column" : "row"}
          spacing={1}
          justifyContent={matchDownSM ? "flex-start" : "space-between"}
          alignItems="center"
          sx={{ p: 3, pb: 0 }}
        >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={searchTerm}
            setGlobalFilter={(e) => {
              setSearchTerm(e);
              // Delay for 500ms
              setTimeout(() => {
                if ((e?.toString().length || -1) > 0) {
                  _DATA.search(e.toLowerCase(), targetId);
                } else {
                  _DATA.search("", targetId);
                }
              }, 500);
            }}
          />
          {/* <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} /> */}
          <Stack direction={matchDownSM ? "column" : "row"} alignItems="center" spacing={2}>
            <Button id="basic-button" onClick={handleAdd} endIcon={<ArrowCircleDown />} variant="contained">
              Add Destination
            </Button>
          </Stack>
        </Stack>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ "& > th:first-of-type": { width: "58px" } }}>
                {headerGroup.headers.map((column) => (
                  <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                    <HeaderSort column={column} sort />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {_DATA.tableLoading ? (
              <TableLoading colSpan={headerGroups[0].headers.length} />
            ) : page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <Fragment key={i}>
                    <TableRow
                      {...row.getRowProps()}
                      // onClick={() => {
                      //   row.toggleRowSelected();
                      // }}
                      sx={{ cursor: "pointer", bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : "inherit" }}
                    >
                      {row.cells.map((cell) => (
                        <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                  </Fragment>
                );
              })
            ) : (
              <EmptyTable msg="No Data" colSpan={12} />
            )}
            <TableRow sx={{ "&:hover": { bgcolor: "transparent !important" } }}>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination
                  gotoPage={(p) => {
                    _DATA.jump(p, searchTerm.toLowerCase(), targetId);
                  }}
                  totalPages={_DATA.maxPage}
                  disablePaging={true}
                  pageIndex={_DATA.currentPage}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </>
  );
}

ReactTable.propTypes = {
  anchorEl: PropTypes.any,
  columns: PropTypes.array,
  data: PropTypes.array,
  targetId: PropTypes.any,
  getHeaderProps: PropTypes.func,
  handleAdd: PropTypes.func,
  handleClick: PropTypes.any,
  handleImport: PropTypes.any,
  handleMenuClose: PropTypes.any,
  isMenuOpen: PropTypes.any,
  _DATA: PropTypes.object,
  renderRowSubComponent: PropTypes.func
};

// ==============================|| CUSTOMER - LIST ||============================== //

const ClientDestination = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  // const [open, setOpen] = useState(false);
  const [destination, setDestination] = useState(null);
  // const [clientDeleteId, setCustomerDeleteId] = useState([]);
  const [add, setAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { token } = useUserAuthInfo();
  const { client, targetId } = useAdminClientId();
  const { destinations } = useSelector((state) => state.setups);
  // const [delData, setDel] = useState({});
  // const handleDelete = () => {
  //   setIsLoading(true);
  //   deleteDestinationList(token, delData.id)
  //     .then(() => {
  //       dispatch(getDestinationList(token, 0, 20));
  //       setOpenAlert(false);
  //       setIsLoading(false);
  //       dispatch(
  //         openSnackbar({
  //           open: true,
  //           message: "Destination deleted successfully.",
  //           variant: "alert",
  //           alert: {
  //             color: "error"
  //           },
  //           close: true
  //         })
  //       );
  //     })
  //     .catch(() => {
  //       setIsLoading(false);
  //       dispatch(
  //         openSnackbar({
  //           open: true,
  //           message: "Error deleting destination. Please try again.",
  //           variant: "alert",
  //           alert: {
  //             color: "error"
  //           },
  //           close: true
  //         })
  //       );
  //     });
  // };
  const data = useMemo(() => _.get(destinations, "content", []), [destinations]);

  //TODO: Filter by targetId
  const _DATA = useServerAdminDestinationPagination(20);
  useEffect(() => {
    dispatch(getService(token));
    if (targetId) {
      _DATA.initDataFetch(targetId).then(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetId]);

  const handleAdd = () => {
    setAdd(!add);
    if (destination && !add) setDestination(null);
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id"
      },
      {
        Header: "Route Code",
        accessor: "routecode"
      },
      {
        Header: "Decription",
        accessor: "description"
      },
      {
        Header: "Service",
        accessor: "service"
      },
      {
        Header: "Charge",
        accessor: "charge"
      },
      {
        Header: "Tax",
        accessor: "tax"
      },
      // {
      //   Header: "Client",
      //   accessor: "clientid",
      //   Cell: ({ value }) => {
      //     const client = find(list, { clientid: value });
      //     return client ? client?.name : "Any";
      //   }
      // },

      {
        Header: "Start Date",
        accessor: "startdate",
        Cell: ({ value }) => {
          return dayjs(value).format("DD MMM YYYY").includes("Invalid") ? "N/A" : dayjs(value).format("DD MMM YYYY");
        }
      },
      {
        Header: "End Date",
        accessor: "enddate",
        Cell: ({ value }) => {
          return dayjs(value).format("DD MMM YYYY").includes("Invalid") ? "N/A" : dayjs(value).format("DD MMM YYYY");
        }
      },

      {
        Header: "Status",
        accessor: "isactive",
        Cell: ({ value }) => {
          switch (value) {
            case false:
              return <Chip color="error" label="Suspened" size="small" variant="light" />;
            case true:
              return <Chip color="success" label="Active" size="small" variant="light" />;
            case "pending":
            default:
          }
        }
      },
      {
        Header: "Actions",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="start" spacing={0}>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                      opacity: 0.9
                    }
                  }
                }}
                title="Edit"
              >
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    // setDestination(row.values.id);
                    getDestinationNoState(token, row.values.id)
                      .then((res) => {
                        setDestination(_.get(res, "data.[0]", {}));
                        handleAdd();
                      })
                      .catch(() => {
                        dispatch(
                          openSnackbar({
                            open: true,
                            message: "Can not edit this destination at this moment.",
                            variant: "alert",
                            alert: {
                              color: "error"
                            },
                            close: false
                          })
                        );
                      });
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                        opacity: 0.9
                      }
                    }
                  }}
                  title="Delete"
                >
                  <IconButton
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDel(row.values);
                      setOpenAlert(true);
                    }}
                  >
                    <Trash />
                  </IconButton>
                </Tooltip> */}
              {/* <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                      opacity: 0.9
                    }
                  }
                }}
                title="Suspend"
              >
                <IconButton
                  color="warning"
                  onClick={() => {
                    // e.stopPropagation();
                    // handleClose();
                    // setCustomerDeleteId(row.values.id);
                  }}
                >
                  <StopCircle />
                </IconButton>
              </Tooltip> */}
              {/* <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                      opacity: 0.9
                    }
                  }
                }}
                title="View"
              >
                <IconButton
                  color="secondary"
                  onClick={() => {
                    // e.stopPropagation();
                    // row.toggleRowExpanded();
                  }}
                >
                  {collapseIcon}
                </IconButton>
              </Tooltip> */}
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme]
  );
  // const renderRowSubComponent = useCallback(({ row }) => <CustomerView data={data[Number(row.id)]} />, [data]);

  if (isLoading) return <Loader />;

  return (
    <>
      <Grid container sx={{ marginBottom: 3 }}>
        <Grid item xs={12}>
          <ClientCTA title="Destinations" user={client ? client?.name : "..."} />
        </Grid>
      </Grid>

      <MainCard content={false}>
        <ScrollX>
          <ReactTable
            columns={columns}
            data={data}
            _DATA={_DATA}
            targetId={targetId}
            handleAdd={handleAdd}
            // renderRowSubComponent={renderRowSubComponent}
          />
        </ScrollX>
        {/* <AlertDelete open={open} title={clientDeleteId.groupname} handleClose={handleClose} handleDelete={handleDelete} /> */}
        {/* add destination dialog */}
        <Dialog
          maxWidth="sm"
          TransitionComponent={PopupTransition}
          keepMounted
          onClose={handleAdd}
          open={add}
          sx={{ "& .MuiDialog-paper": { p: 0 }, transition: "transform 225ms" }}
          aria-describedby="alert-dialog-slide-description"
        >
          <AddDestination isClient users={[]} d={destination} onCancel={handleAdd} />
        </Dialog>
      </MainCard>
      {/* <AlertDelete handleDelete={handleDelete} title={delData.routecode} open={openAlert} handleClose={handleAlertClose} /> */}
    </>
  );
};

ClientDestination.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  avatar: PropTypes.object,
  message: PropTypes.string,
  fatherName: PropTypes.string,
  email: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  id: PropTypes.number
};

export default ClientDestination;
