// project-imports
import axios from "utils/axios";
// third-party
import { createSlice } from "@reduxjs/toolkit";

// fetch countries fro api

const initialState = {
  error: null,
  countries: [],
  services: [],
  bundleCategory: [],
  usertypes: [],
  senderidStatus: []
};

// ==============================|| SLICE - OPTIONS ||============================== //

const options = createSlice({
  name: "options",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // fetch countries
    setCountries(state, action) {
      state.countries = action.payload;
    },

    // fetch services
    setServices(state, action) {
      state.services = action.payload;
    },

    setBundleCategory(state, action) {
      state.bundleCategory = action.payload;
    },

    // fetch user types
    setUserTypes(state, action) {
      state.usertypes = action.payload;
    },

    setSenderIdStatus(state, action) {
      state.senderidStatus = action.payload;
    }
  }
});

export const { setCountries, hasError, setBundleCategory, setServices, setUserTypes, setSenderIdStatus } = options.actions;

export default options.reducer;

/**
 * Fetches countries data from the server.
 *
 * @param {string} token - The authentication token.
 * @returns {Function} - The async function that dispatches actions based on the API response.
 */
export function getCountries(token) {
  return async (dispatch) => {
    try {
      const response = await axios.get("/options/countries", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(setCountries(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Fetches the service data from the server.
 *
 * @param {string} token - The authentication token.
 * @returns {Function} - The async function that dispatches the action.
 */
export function getService(token) {
  return async (dispatch) => {
    try {
      const response = await axios.get("/options/service", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(setServices(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getBundleCategory(token) {
  return async (dispatch) => {
    try {
      const response = await axios.get("/options/bundlecategory", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(setBundleCategory(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Fetches user types from the server.
 * @param {string} token - The authentication token.
 * @returns {Function} - An async function that dispatches actions based on the server response.
 */
export function getUserTypes(token) {
  return async (dispatch) => {
    try {
      const response = await axios.get("/options/usertype", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(setUserTypes(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getSenderIdStatus(token) {
  return async (dispatch) => {
    try {
      const response = await axios.get("/options/senderidstatus", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(setSenderIdStatus(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
