import { Chip, Dialog, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery } from "@mui/material";
import { Edit, Eye, Personalcard } from "iconsax-react";
// assets
// import { Bill, CardCoin, Coin, Personalcard, Share, Tag2, UserOctagon } from "iconsax-react";
import { EmptyTable, HeaderSort, SortingSelect, TableLoading, TablePagination, TableRowSelection } from "components/third-party/ReactTable";
import { Fragment, useEffect, useMemo, useState } from "react";
import { GlobalFilter, renderFilterTypes } from "utils/react-table";
// material-ui
import { alpha, useTheme } from "@mui/material/styles";
import {
  getAllUsers
  //  getClients
} from "store/reducers/users";
import { useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";

import AddSenderId from "../addSenderId";
import IconButton from "components/@extended/IconButton";
// import { LOGOUT } from "store/reducers/actions";
import Loader from "components/Loader";
// project-imports
import MainCard from "components/MainCard";
import { PopupTransition } from "components/@extended/Transitions";
// third-party
// import { PopupTransition } from "components/@extended/Transitions";
import PropTypes from "prop-types";
import ReviewId from "../ReviewId";
import ScrollX from "components/ScrollX";
import { ThemeMode } from "config";
import { dispatch } from "store";
import { get } from "lodash";
import { getSenderIdStatus } from "store/reducers/options";
import { useSelector } from "store";
import { useServerAdminSenderIdsPagination } from "hooks/useServerPagination";
import useUserAuthInfo from "hooks/useAuthInfo";
import { useNavigate } from "react-router";
import dayjs from "dayjs";

// import { useNavigate } from "react-router";

// import makeData from "data/react-table";

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, _DATA }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const filterTypes = useMemo(() => renderFilterTypes, []);
  const sortBy = { id: "status", desc: true };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // setHiddenColumns,
    allColumns,
    visibleColumns,
    page,
    state: { selectedRowIds, expanded },
    preGlobalFilteredRows,
    setSortBy
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ["id", "clientid"], sortBy: [sortBy] }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  // useEffect(() => {
  //   if (matchDownSM) {
  //     setHiddenColumns(["age", "contact", "visits", "email", "status", "avatar"]);
  //   } else {
  //     setHiddenColumns(["avatar", "email"]);
  //   }
  //   // eslint-disable-next-line
  // }, [matchDownSM]);
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <>
      <TableRowSelection selected={Object.keys(selectedRowIds).length} />
      <Stack spacing={3}>
        <Stack
          direction={matchDownSM ? "column" : "row"}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: 0 }}
        >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={searchTerm}
            setGlobalFilter={(e) => {
              setSearchTerm(e);
              // Delay for 500ms
              setTimeout(() => {
                if ((e?.toString().length || -1) > 0) {
                  _DATA.search(e.toLowerCase());
                } else {
                  _DATA.search("");
                }
              }, 500);
            }}
          />
          {/* <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} /> */}
          <Stack direction={matchDownSM ? "column" : "row"} alignItems="center" spacing={2}>
            <SortingSelect sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} />
            {/* <Button variant="contained" startIcon={<Add />} onClick={handleAdd}>
              Add SenderId
            </Button> */}
            {/* <CSVExport data={selectedFlatRows.length > 0 ? selectedFlatRows.map((d) => d.original) : data} filename={"senderid-list.csv"} /> */}
          </Stack>
        </Stack>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ "& > th:first-of-type": { width: "58px" } }}>
                {headerGroup.headers.map((column) => (
                  <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                    <HeaderSort column={column} sort />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {_DATA.tableLoading ? (
              <TableLoading colSpan={headerGroups[0].headers.length} />
            ) : page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();

                return (
                  <Fragment key={i}>
                    <TableRow
                      {...row.getRowProps()}
                      onClick={() => {
                        row.toggleRowSelected();
                      }}
                      sx={{ cursor: "pointer", bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : "inherit" }}
                    >
                      {row.cells.map((cell) => (
                        <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                  </Fragment>
                );
              })
            ) : (
              <EmptyTable msg="No Data" colSpan={9} />
            )}
            <TableRow sx={{ "&:hover": { bgcolor: "transparent !important" } }}>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination
                  gotoPage={(p) => {
                    _DATA.jump(p, searchTerm.toLowerCase());
                  }}
                  totalPages={_DATA.maxPage}
                  disablePaging={true}
                  pageIndex={_DATA.currentPage}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array,
  _DATA: PropTypes.object,
  data: PropTypes.array,
  getHeaderProps: PropTypes.func,
  handleAdd: PropTypes.func,
  renderRowSubComponent: PropTypes.any
};

// ==============================|| SENDER ID - LIST ||============================== //

const PendingSenderIds = () => {
  const { token } = useUserAuthInfo();

  const theme = useTheme();
  const mode = theme.palette.mode;
  const adminSenderids = useSelector((state) => state.messaging.adminSenderids);
  const {
    // clients,

    allUsers
  } = useSelector((state) => state.usersmanagement);
  const senderidStatus = useSelector((state) => state.options.senderidStatus);

  // const clientList = useMemo(() => get(clients, "content", []), [clients]);
  const usersList = useMemo(() => get(allUsers, "content", []), [allUsers]);
  const data = useMemo(() => get(adminSenderids, "data.content", []), [adminSenderids]);
  // console.log("🚀 ~ PendingSenderIds ~ data:", data);
  // console.log("🚀 ~ SenderIds ~ list:", list);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [senderid, setSenderId] = useState(null);
  const [add, setAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSenderId, setSelectedSenderId] = useState(null);
  const _DATA = useServerAdminSenderIdsPagination(20, "PENDING");

  useEffect(() => {
    dispatch(getAllUsers(token, "STAFF"));
    dispatch(getSenderIdStatus(token));
    // dispatch(getClients(token))
    //   .then(() => {
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     if (error.status === "PERMISSION_DENIED") {
    //       dispatch({ type: LOGOUT });
    //     }
    //   });
    _DATA.initDataFetch().then(() => setIsLoading(false));
  }, [token]);

  const handleAdd = () => {
    setAdd(!add);
    if (senderid && !add) setSenderId(null);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleReview = (selectedData) => {
    setSelectedSenderId(selectedData);
    setOpen(!open);
  };

  const columns = useMemo(
    () => [
      // {
      //   title: "Row Selection",
      //   Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />,
      //   accessor: "selection",
      //   Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
      //   disableSortBy: true
      // },
      {
        Header: " ID",
        accessor: "id"
      },
      {
        Header: "Sender ID",
        accessor: "senderid",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                minWidth: "150px",
                maxWidth: "300px"
              }}
            >
              {value}
            </div>
          );
        }
      },

      {
        Header: "Client",
        accessor: "clientid"
        // Cell: ({ value }) => {
        //   const client = clientList.find((item) => item.id === value);
        //   return client ? client.name : "N/A";
        // }
      },
      {
        Header: " Active",
        accessor: "isactive",
        Cell: ({ value }) => {
          switch (value) {
            case false:
              return <Chip color="error" label="inacive" size="small" variant="light" />;
            case true:
              return <Chip color="success" label="active" size="small" variant="light" />;
            default:
          }
        }
      },
      {
        Header: " Status",
        accessor: "status",
        Cell: ({ value }) => {
          if (value === "PENDING") {
            return <Chip color="warning" label="pending" size="small" variant="light" />;
          } else {
            return null;
          }
        }
        // Cell: ({ value }) => {
        //   switch (value) {
        //     case "APPROVED":
        //       return <Chip color="success" label="approved" size="small" variant="light" />;
        //     case "REJECTED":
        //       return <Chip color="error" label="rejected" size="small" variant="light" />;
        //     default:
        //       return <Chip color="warning" label="pending" size="small" variant="light" />;
        //   }
        // }
      },
      {
        Header: "Date Submitted",
        accessor: "createdat",
        Cell: ({ value }) => {
          return dayjs(value).format("DD MMM YYYY");
        }
      },
      {
        Header: "Updated At",
        accessor: "updatedat",
        Cell: ({ value }) => {
          return value ? dayjs(value).format("DD MMM YYYY") : "N/A";
        }
      },

      {
        Header: "Actions",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" spacing={0}>
              {/* View Client Profile */}
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                      opacity: 0.9
                    }
                  }
                }}
                title="View Client"
              >
                <IconButton
                  color="warning"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/admin/clients/profile/${row.values.clientid}`, { state: { clientid: row.values.clientid } });
                  }}
                >
                  <Personalcard />
                </IconButton>
              </Tooltip>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                      opacity: 0.9
                    }
                  }
                }}
                title="Review Sender ID"
              >
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    // setPlan(row.values);
                    handleReview(row.values);
                  }}
                >
                  <Eye />
                </IconButton>
              </Tooltip>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                      opacity: 0.9
                    }
                  }
                }}
                title="Edit"
              >
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSenderId(row.values);
                    handleAdd();
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme, usersList]
  );

  // const renderRowSubComponent = useCallback(({ row }) => <CustomerView data={data[Number(row.id)]} />, [data]);

  if (isLoading) return <Loader />;

  return (
    <MainCard content={false}>
      <ScrollX>
        <ReactTable _DATA={_DATA} columns={columns} data={data} handleAdd={handleAdd} />
      </ScrollX>
      <ReviewId token={token} status={senderidStatus} senderid={selectedSenderId} open={open} onClose={handleClose} />
      <Dialog
        maxWidth="sm"
        TransitionComponent={PopupTransition}
        keepMounted
        onClose={handleAdd}
        open={add}
        sx={{ "& .MuiDialog-paper": { p: 0 }, transition: "transform 225ms" }}
        aria-describedby="alert-dialog-slide-description"
      >
        <AddSenderId token={token} senderid={senderid} onCancel={handleAdd} />
      </Dialog>{" "}
    </MainCard>
  );
};

PendingSenderIds.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  avatar: PropTypes.object,
  message: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  id: PropTypes.number
};

export default PendingSenderIds;
