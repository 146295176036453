import AddressBook from "pages/admin/address-book";
import AuthGuard from "utils/route-guard/AuthGuard";
import BannedSenderIds from "pages/admin/senderids/banned";
import BulkMessaging from "pages/admin/bulk-messaging";
import ClientBundles from "pages/admin/settings/bundles";
import ClientDestinations from "pages/admin/settings/destinations";
import ClientSettings from "pages/admin/settings";
import Clientx from "pages/admin/credits";
import Loadable from "components/Loadable";
// project-imports
import MainLayout from "layout/MainLayout";
import PendingSenderIds from "pages/admin/senderids/pending";
import { lazy } from "react";

// render - admin
const AdminDashboard = Loadable(lazy(() => import("pages/admin/dashboard")));
const Clients = Loadable(lazy(() => import("pages/admin/clients/client-list")));
const ClientUserList = Loadable(lazy(() => import("pages/admin/clients/client-users")));
const ClientPlans = Loadable(lazy(() => import("pages/admin/clients/plans")));
const ClientTaxes = Loadable(lazy(() => import("pages/admin/clients/taxes")));
const ClientCreditLimits = Loadable(lazy(() => import("pages/admin/clients/credit-limits")));
const ClientCredit = Loadable(lazy(() => import("pages/admin/clients/credits")));
const UserAccount = Loadable(lazy(() => import("pages/admin/clients/user-accounts")));
const SenderidDetails = Loadable(lazy(() => import("pages/admin/clients/senderids/details")));
const ClientFeedbacks = Loadable(lazy(() => import("pages/admin/clients/feedbacks")));
const PostPaidClients = Loadable(lazy(() => import("pages/admin/clients/postpaid-clients")));
const FilterMessages = Loadable(lazy(() => import("pages/admin/messages/filter")));
const FilterReports = Loadable(lazy(() => import("pages/admin/messages/reports/filter")));
const Billing = Loadable(lazy(() => import("pages/admin/billing")));
const BillSummary = Loadable(lazy(() => import("pages/admin/billing/bill-summary")));
const BillingOrders = Loadable(lazy(() => import("pages/admin/billing/bill-orders")));
const BillingPayments = Loadable(lazy(() => import("pages/admin/billing/payments")));
const BillClients = Loadable(lazy(() => import("pages/admin/billing/bill-clients")));
const BillingProviders = Loadable(lazy(() => import("pages/admin/billing/billing-providers")));
const Fees = Loadable(lazy(() => import("pages/admin/billing/fees")));
const Terms = Loadable(lazy(() => import("pages/admin/billing/terms")));
const BillingCycles = Loadable(lazy(() => import("pages/admin/billing/billing-cycles")));
const RatePlan = Loadable(lazy(() => import("pages/admin/billing/providers/rate-plan")));
const Destinations = Loadable(lazy(() => import("pages/admin/billing/destinations")));
const RatePackages = Loadable(lazy(() => import("pages/admin/rate-packages")));
const RatePlans = Loadable(lazy(() => import("pages/admin/rate-plans")));
const Services = Loadable(lazy(() => import("pages/admin/services")));
const Providers = Loadable(lazy(() => import("pages/admin/providers")));
const UserList = Loadable(lazy(() => import("pages/admin/users/")));
const SenderIds = Loadable(lazy(() => import("pages/admin/senderids")));
const SMPPS = Loadable(lazy(() => import("pages/admin/smpps")));
const HomeMessages = Loadable(lazy(() => import("pages/admin/homepage/messages")));
const WithDrawals = Loadable(lazy(() => import("pages/admin/referral")));
const Bundles = Loadable(lazy(() => import("pages/admin/bundles")));
const Error404 = Loadable(lazy(() => import("pages/maintenance/error/404")));
const Profile = Loadable(lazy(() => import("pages/profile")));
const InvioceDetails = Loadable(lazy(() => import("pages/credits/invoices/details")));
// const PaymentCallback = Loadable(lazy(() => import("pages/payment-callback")));

// reder bulk messaging
const SendSMS = Loadable(lazy(() => import("pages/bulk-messaging/send-message/sms")));
const SenderId = Loadable(lazy(() => import("pages/bulk-messaging/senderids")));
const SentMessages = Loadable(lazy(() => import("pages/bulk-messaging/sent-messages")));
const ScheduledMessages = Loadable(lazy(() => import("pages/bulk-messaging/scheduled-messages")));
const MessageSummary = Loadable(lazy(() => import("pages/bulk-messaging/summary")));
const AnniversaryAlerts = Loadable(lazy(() => import("pages/bulk-messaging/schedule-message")));
const AnniversaryVoice = Loadable(lazy(() => import("pages/bulk-messaging/schedule-message/schedule")));
const ScheduledMessageSummary = Loadable(lazy(() => import("pages/bulk-messaging/schedule-message/schedule/summary")));

// render - address book
const Contacts = Loadable(lazy(() => import("pages/address-book/contacts")));
const ManageGroups = Loadable(lazy(() => import("pages/address-book/manage-groups/index")));
const GroupDetails = Loadable(lazy(() => import("pages/address-book/manage-groups/Details")));
const BulkImports = Loadable(lazy(() => import("pages/address-book/bulk-imports")));

// render - credits
const CreditInvoiceList = Loadable(lazy(() => import("pages/credits/invoices")));
const Bundle = Loadable(lazy(() => import("pages/credits/bundles")));
const Balance = Loadable(lazy(() => import("pages/credits/balance")));
const InvoiceDetails = Loadable(lazy(() => import("pages/credits/invoices/details")));

const AdminRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard allowedRoles={["ADMIN"]}>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "admin",
          children: [
            {
              path: "dashboard",
              element: <AdminDashboard />
            },
            {
              path: "client/bulk-messaging/:clientid",
              element: <BulkMessaging />
            },
            {
              path: "client/address-book/:clientid",
              element: <AddressBook />
            },
            {
              path: "client/clients/:clientid",
              element: <Clientx />
            },
            {
              path: "client/settings/:clientid",
              element: <ClientSettings />
            },

            {
              path: "clients",
              children: [
                //!* settings
                {
                  path: "settings",
                  children: [
                    {
                      path: "bundles/:clientid",
                      element: <ClientBundles />
                    },
                    {
                      path: "destinations/:clientid",
                      element: <ClientDestinations />
                    }
                  ]
                },
                //!* messaging
                {
                  path: "bulk-messaging",
                  children: [
                    {
                      path: "send-message/:clientid",
                      element: <SendSMS />
                    },
                    {
                      path: "sent-messages/:clientid",
                      element: <SentMessages />
                    },
                    {
                      path: "scheduled-messages/:clientid",
                      element: <ScheduledMessages />
                    },
                    {
                      path: "summary/:clientid",
                      element: <MessageSummary />
                    },
                    {
                      path: "schedule-a-message/:clientid",
                      element: <AnniversaryAlerts />
                    },
                    {
                      path: "schedule-a-message/schedule/:clientid",
                      element: <AnniversaryVoice />
                    },
                    {
                      path: "scheduled-a-messages/summary/:clientid",
                      element: <ScheduledMessageSummary />
                    },

                    {
                      path: "senderids/:clientid",
                      element: <SenderId />
                    }
                  ]
                },

                //!* address-book
                {
                  path: "address-book",
                  children: [
                    {
                      path: "contacts",
                      element: <Contacts />
                    },
                    {
                      path: "groups/:clientid",
                      element: <ManageGroups />
                    },
                    {
                      path: "manage-group/:clientid/group/:groupid",
                      element: <GroupDetails />
                    },
                    {
                      path: "bulk-imports/:clientid",
                      element: <BulkImports />
                    }
                  ]
                },

                //!* credits

                {
                  path: "credits",
                  children: [
                    {
                      path: "balance/:clientid",
                      element: <Balance />
                    },
                    {
                      path: "bundles/:clientid",
                      element: <Bundle />
                    },
                    {
                      path: "invoices/:clientid",
                      element: <CreditInvoiceList />
                    },
                    {
                      path: "invoices/:id/view/:invoiceid",
                      element: <InvoiceDetails />
                    }
                  ]
                },

                {
                  path: "client-list",
                  element: <Clients />
                },
                {
                  path: "user-list",
                  element: <ClientUserList />
                },
                {
                  path: "profile/:client_id",
                  element: <Profile />
                },
                {
                  path: "plans",
                  element: <ClientPlans />
                },
                {
                  path: "taxes",
                  element: <ClientTaxes />
                },
                {
                  path: "credit-limits",
                  element: <ClientCreditLimits />
                },
                {
                  path: "senderids/details",
                  element: <SenderidDetails />
                },
                {
                  path: "credits/details",
                  element: <ClientCredit />
                },
                {
                  path: "user-accounts",
                  element: <UserAccount />
                },
                {
                  path: "feedbacks",
                  element: <ClientFeedbacks />
                },
                {
                  path: "postpaid-clients",
                  element: <PostPaidClients />
                }
              ]
            },
            {
              path: "senderids",
              element: <SenderIds />
            },
            {
              path: "senderids/pending",
              element: <PendingSenderIds />
            },
            {
              path: "senderids/banned",
              element: <BannedSenderIds />
            },
            {
              path: "smpps",
              element: <SMPPS />
            },
            {
              path: "homepage/messages",
              element: <HomeMessages />
            },
            {
              path: "referral",
              element: <WithDrawals />
            },
            {
              // path: "bundles",
              children: [
                {
                  path: "bundles",
                  element: <Bundles />
                }
                // {
                //   path: "reports",
                //   children: [
                //     {
                //       path: "filter",
                //       element: <FilterReports />
                //     }
                //   ]
                // }
              ]
            },
            {
              path: "messages",
              children: [
                {
                  path: "filter",
                  element: <FilterMessages />
                },
                {
                  path: "reports",
                  children: [
                    {
                      path: "filter",
                      element: <FilterReports />
                    }
                  ]
                }
              ]
            },

            {
              path: "rate-packages",
              element: <RatePackages />
            },
            {
              path: "rate-plans",
              element: <RatePlans />
            },
            {
              path: "services",
              element: <Services />
            },
            {
              path: "providers",
              element: <Providers />
            },
            {
              path: "users",
              element: <UserList />
            },
            {
              path: "billing",
              children: [
                {
                  path: "destinations",
                  element: <Destinations />
                },
                {
                  path: "billing",
                  element: <Billing />
                },
                {
                  path: "bill-summary",
                  element: <BillSummary />
                },
                {
                  path: "bill-orders/:id",
                  element: <BillingOrders />
                },
                {
                  path: "payments",
                  element: <BillingPayments />
                },
                {
                  path: "bill-clients",
                  element: <BillClients />
                },
                {
                  path: "billing-providers",
                  element: <BillingProviders />
                },
                {
                  path: "fees",
                  element: <Fees />
                },
                {
                  path: "terms",
                  element: <Terms />
                },
                {
                  path: "billing-cycles",
                  element: <BillingCycles />
                },
                {
                  path: "providers",
                  children: [
                    {
                      path: "rate-plan",
                      element: <RatePlan />
                    }
                  ]
                }
              ]
            },
            {
              path: "billing",
              children: [
                {
                  path: "billing",
                  element: <Billing />
                },
                {
                  path: "bill-summary",
                  element: <BillSummary />
                },
                {
                  path: "bill-orders",
                  element: <BillingOrders />
                },
                {
                  path: "payments",
                  element: <BillingPayments />
                },
                {
                  path: "invoices/:id",
                  element: <InvioceDetails />
                },
                {
                  path: "bill-clients",
                  element: <BillClients />
                },
                {
                  path: "billing-providers",
                  element: <BillingProviders />
                },
                {
                  path: "fees",
                  element: <Fees />
                },
                {
                  path: "terms",
                  element: <Terms />
                },
                {
                  path: "billing-cycles",
                  element: <BillingCycles />
                },
                {
                  path: "providers",
                  children: [
                    {
                      path: "rate-plan",
                      element: <RatePlan />
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: "404",
      element: <Error404 />
    }
  ]
};

export default AdminRoutes;
