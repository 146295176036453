// assets
import {
  Add,
  Edit,
  Eye
  //  Shield, ShieldSlash
} from "iconsax-react";
import { Button, Chip, Dialog, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery } from "@mui/material";
import {
  EmptyTable,
  HeaderSort,
  IndeterminateCheckbox,
  TableLoading,
  TablePagination,
  TableRowSelection
} from "components/third-party/ReactTable";
import { Fragment, useEffect, useMemo, useState } from "react";
import { GlobalFilter, renderFilterTypes } from "utils/react-table";
// import { activateService, deactivateService, getBundlesByIdNoState } from "store/reducers/setups";
// material-ui
import { alpha, useTheme } from "@mui/material/styles";
import { useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";

import AlertDelete from "components/AlertDelete";
import IconButton from "components/@extended/IconButton";
import Loader from "components/Loader";
// project-imports
import MainCard from "components/MainCard";
// third-party
import { PopupTransition } from "components/@extended/Transitions";
import PropTypes from "prop-types";
import ScrollX from "components/ScrollX";
import { ThemeMode } from "config";
import { dispatch } from "store";
import { get } from "lodash";
import { getDestinationList } from "store/reducers/setups";
import { getBundleCategory, getService } from "store/reducers/options";
import { useSelector } from "store";
import { useServerAdminBundlePagination } from "hooks/useServerPagination";
import useUserAuthInfo from "hooks/useAuthInfo";
// import { openSnackbar } from "store/reducers/snackbar";
import ClientCTA from "pages/admin/ClientCTA";
import useAdminClientId from "hooks/useAdminClientId";
import AddBundle from "pages/admin/bundles/AddBundle";
import ViewBundle from "pages/admin/bundles/ViewBundle";

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ targetId, columns, data, renderRowSubComponent, handleAdd, _DATA }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  // const { destinations } = useSelector((state) => state.setups);

  const filterTypes = useMemo(() => renderFilterTypes, []);
  // const sortBy = { id: "bundleName", desc: false };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    state: { selectedRowIds, expanded },
    preGlobalFilteredRows
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: [
          "id",
          "clientid",
          "bgcolor",
          "createdby",
          "issystembundle",
          "startdate",
          "enddate",
          "updatdedat",
          "updatedby",
          "createdat"
        ]
        // sortBy: [sortBy]
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      <TableRowSelection selected={Object.keys(selectedRowIds).length} />
      <Stack spacing={3}>
        <Stack
          direction={matchDownSM ? "column" : "row"}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: 0 }}
        >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={searchTerm}
            setGlobalFilter={(e) => {
              setSearchTerm(e);
              // Delay for 500ms
              setTimeout(() => {
                if ((e?.toString().length || -1) > 0) {
                  _DATA.search(e.toLowerCase(), targetId);
                } else {
                  _DATA.search("", targetId);
                }
              }, 500);
            }}
          />
          {/* <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} /> */}
          <Stack direction={matchDownSM ? "column" : "row"} alignItems="center" spacing={2}>
            <Button variant="contained" startIcon={<Add />} onClick={handleAdd}>
              Add Bundles
            </Button>
            {/* <CSVExport data={selectedFlatRows.length > 0 ? selectedFlatRows.map((d) => d.original) : data} filename={"fees-list.csv"} /> */}
          </Stack>
        </Stack>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ "& > th:first-of-type": { width: "58px" } }}>
                {headerGroup.headers.map((column) => (
                  <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                    <HeaderSort column={column} sort />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {_DATA.tableLoading ? (
              <TableLoading colSpan={headerGroups[0].headers.length} />
            ) : page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();

                return (
                  <Fragment key={i}>
                    <TableRow
                      {...row.getRowProps()}
                      onClick={() => {
                        row.toggleRowSelected();
                      }}
                      sx={{ cursor: "pointer", bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : "inherit" }}
                    >
                      {row.cells.map((cell) => (
                        <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                  </Fragment>
                );
              })
            ) : (
              <EmptyTable msg="No Data" colSpan={9} />
            )}
            <TableRow sx={{ "&:hover": { bgcolor: "transparent !important" } }}>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination
                  gotoPage={(p) => {
                    _DATA.jump(p, searchTerm.toLowerCase(), targetId);
                  }}
                  totalPages={_DATA.maxPage}
                  disablePaging={true}
                  pageIndex={_DATA.currentPage}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  targetId: PropTypes.any,
  _DATA: PropTypes.object,
  getHeaderProps: PropTypes.func,
  handleAdd: PropTypes.func,
  renderRowSubComponent: PropTypes.any
};

// ==============================|| BUNDLE - LIST ||============================== //

const ClientBundles = () => {
  const { token } = useUserAuthInfo();
  const { client, targetId } = useAdminClientId();
  const theme = useTheme();

  const mode = theme.palette.mode;
  const [open, setOpen] = useState(false);
  const [bundleView, setBundleView] = useState({});
  const [editBundleData, setEditBundleData] = useState();
  const [add, setAdd] = useState(false);
  // const [list, setList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const { bundles } = useSelector((state) => state.setups);
  const { clients } = useSelector((state) => state.usersmanagement);

  const userLists = get(clients, "content", []);
  const PER_PAGE = 20;
  const _DATA = useServerAdminBundlePagination(PER_PAGE);
  useEffect(() => {
    dispatch(getDestinationList(token, 0, 1000, ""));
    dispatch(getService(token));
    dispatch(getBundleCategory(token));
    if (targetId) {
      _DATA.initDataFetch(targetId).then(() => setIsLoading(false));
    }
  }, [token, targetId]);

  const data = useMemo(() => _.get(bundles, "data.content", []), [bundles]);

  const handleAdd = () => {
    setAdd(!add);
    if (editBundleData && !add) setEditBundleData();
  };

  const handleClose = () => {
    // dispatch(deleteProviderList(deleteId));
    setOpen(!open);
  };

  // const handleActivate = (serviceId) => {
  //   dispatch(activateService(serviceId));
  // };

  // const handleDeactivate = (serviceId) => {
  //   dispatch(deactivateService(serviceId));
  // };

  const handleViewBundle = () => {
    setOpen(!open);
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id"
      },
      {
        Header: "System Bundle",
        accessor: "issystembundle"
      },
      {
        Header: "Start Date",
        accessor: "startdate"
      },
      {
        Header: "End Date",
        accessor: "enddate"
      },
      {
        Header: "Updated At",
        accessor: "updatdedat"
      },
      {
        Header: "Updated By",
        accessor: "updatedby"
      },
      {
        Header: "Created At",
        accessor: "createdat"
      },
      {
        title: "Row Selection",
        Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />,
        accessor: "selection",
        Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
        disableSortBy: true
      },

      {
        Header: "Bundle  Name",
        accessor: "name"
      },
      {
        Header: "Category",
        accessor: "bundlecategory"
      },
      {
        Header: "Bg. Color",
        accessor: "bgcolor"
      },
      {
        Header: "Amount (GHS)",
        accessor: "price"
      },
      {
        Header: "Tax Amount",
        accessor: "tax"
      },
      {
        Header: "Bun. Valid Days",
        accessor: "daysvalid"
        // Cell: ({ value }) => {
        //   return (
        //     <Box
        //       sx={{
        //         minWidth: { xs: "10px", md: "20px" },
        //         maxWidth: "30px",
        //         overflow: "hidden",
        //         textOverflow: "ellipsis"
        //       }}
        //     >
        //       {value}
        //     </Box>
        //   );
        // }
      },
      {
        Header: "Inv. Valid Days",
        accessor: "invoicedaysvalid"
      },
      {
        Header: "Client ID",
        accessor: "clientid"
      },
      {
        Header: "Created By",
        accessor: "createdby",
        Cell: ({ value }) => {
          const user = userLists.find((user) => user.id === value);
          return user ? user.name : "N/A";
        }
      },
      {
        Header: " Status",
        accessor: "isactive",
        Cell: ({ value }) => {
          switch (value) {
            case true:
              return <Chip color="success" label="active" size="small" variant="light" />;
            case false:
              return <Chip color="error" label="inactive" size="small" variant="light" />;
            default:
          }
        }
      },

      {
        Header: "Actions",
        disableSortBy: true,
        Cell: ({ row }) => {
          // const isActive = row.values.status === "Active";
          return (
            <Stack direction="row" alignItems="center" spacing={0}>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                      opacity: 0.9
                    }
                  }
                }}
                title="View"
              >
                <IconButton
                  color="warning"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                    setBundleView(row.values);
                    console.log("🚀 ~ Bundles ~ row.values:", row.values);
                    handleViewBundle();
                  }}
                >
                  <Eye />
                </IconButton>
              </Tooltip>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                      opacity: 0.9
                    }
                  }
                }}
                title="Edit"
              >
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditBundleData(row.values);
                    // setIsLoading(true);
                    handleAdd();
                    //get the bundle data by id
                    // getBundlesByIdNoState(token, row.values.id)
                    //   .then((res) => {
                    //     console.log("🚀 ~ .then ~ res:", get(res, "data", []));
                    //     setEditBundleData({
                    //       ...row.values,
                    //       details: get(res, "data", [])
                    //     });
                    //     handleAdd();
                    //   })
                    //   .catch(() => {
                    //     dispatch(
                    //       openSnackbar({
                    //         open: true,
                    //         message: "Can not edit this bundle at this moment. Please try again later.",
                    //         variant: "alert",
                    //         alert: {
                    //           color: "error"
                    //         },
                    //         close: false
                    //       })
                    //     );
                    //   })
                    //   .finally(() => {
                    //     setIsLoading(false);
                    //   });
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* {isActive ? (
                <Tooltip title="Deactivate">
                  <IconButton
                    color="warning"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeactivate(row.values.id);
                    }}
                  >
                    <ShieldSlash />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Activate">
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleActivate(row.values.id);
                    }}
                  >
                    <Shield />
                  </IconButton>
                </Tooltip>
              )} */}
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme, userLists]
  );

  if (isLoading) return <Loader />;

  return (
    <>
      <Grid container sx={{ marginBottom: 3 }}>
        <Grid item xs={12}>
          <ClientCTA title="Bundles" user={client ? client?.name : "..."} />
        </Grid>
      </Grid>
      <MainCard content={false}>
        <ScrollX>
          <ReactTable targetId={targetId} columns={columns} _DATA={_DATA} data={data} handleAdd={handleAdd} />
        </ScrollX>
        <AlertDelete open={open} handleClose={handleClose} />
        {/* add fee dialog */}
        <Dialog
          TransitionComponent={PopupTransition}
          keepMounted
          onClose={handleAdd}
          open={add}
          sx={{ "& .MuiDialog-paper": { p: 0 }, transition: "transform 225ms" }}
          aria-describedby="alert-dialog-slide-description"
        >
          <AddBundle isClient onCancel={handleAdd} users={[]} b={editBundleData} />
        </Dialog>
        <ViewBundle bundle={bundleView} open={open} onClose={handleClose} />
      </MainCard>
    </>
  );
};

ClientBundles.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  avatar: PropTypes.object,
  message: PropTypes.string,
  client: PropTypes.string,
  email: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  id: PropTypes.number
};

export default ClientBundles;
