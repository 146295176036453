import { Container, Grid } from "@mui/material";
import { DirectSend, MessageSearch, MessageTime, TimerStart, UserOctagon } from "iconsax-react";

import ClientCTA from "./ClientCTA";
import Template from "./template";
import useAdminClientId from "hooks/useAdminClientId";
import { useTheme } from "@mui/material/styles";

// import { useParams } from "react-router";

// import userStats1 from "assets/images/widget/userstats1.svg";
// import userStats2 from "assets/images/widget/userstats2.svg";
// import userStats3 from "assets/images/widget/userstats3.svg";
// import userStats4 from "assets/images/widget/userstats4.svg";

const BulkMessaging = () => {
  const theme = useTheme();
  const { targetId, client } = useAdminClientId();
  // console.log("🚀 ~ BulkMessaging ~ targetId:", targetId);

  // const downMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ClientCTA title="Bulk Messaging" showDo user={client ? client?.name : "..."} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                {/* <Template
            primary="Send Message"
            iconPrimary={<MessageAdd color="#FF8A65" />}
            link={`/admin/clients/bulk-messaging/send-message/${targetId}`}
          /> */}
                <Template
                  link={`/admin/clients/bulk-messaging/send-message/${targetId}`}
                  primary="Send Message"
                  color={theme.palette.primary.main}
                  iconPrimary={DirectSend}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <Template
                  link={`/admin/clients/bulk-messaging/schedule-a-message/schedule/${targetId}`}
                  primary="Schedule A Message"
                  color={theme.palette.primary.main}
                  iconPrimary={TimerStart}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <Template
                  link={`/admin/clients/bulk-messaging/sent-messages/${targetId}`}
                  primary="View Sent Messages"
                  color={theme.palette.primary.main}
                  iconPrimary={MessageSearch}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <Template
                  link={`/admin/clients/bulk-messaging/scheduled-messages/${targetId}`}
                  primary="View Scheduled Messages"
                  color={theme.palette.primary.main}
                  iconPrimary={MessageTime}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <Template
                  link={`/admin/clients/bulk-messaging/senderids/${targetId}`}
                  primary="View Sender IDs"
                  color={theme.palette.primary.main}
                  iconPrimary={UserOctagon}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default BulkMessaging;
