import { ArchiveBook, Import } from "iconsax-react";
import { Container, Grid } from "@mui/material";

import ClientCTA from "./ClientCTA";
import Template from "./template";
import useAdminClientId from "hooks/useAdminClientId";
import { useTheme } from "@mui/material/styles";

// import { useParams } from "react-router";

// import userStats1 from "assets/images/widget/userstats1.svg";
// import userStats2 from "assets/images/widget/userstats2.svg";
// import userStats3 from "assets/images/widget/userstats3.svg";
// import userStats4 from "assets/images/widget/userstats4.svg";

const AdressBook = () => {
  const { targetId, client } = useAdminClientId();

  const theme = useTheme();

  // const downMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ClientCTA title={"Address Book"} showDo user={client ? client?.name : "..."} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <Template
                  link={`/admin/clients/address-book/groups/${targetId}`}
                  primary="Manage Groups"
                  color={theme.palette.primary.main}
                  iconPrimary={ArchiveBook}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <Template
                  link={`/admin/clients/address-book/bulk-imports/${targetId}`}
                  primary="Import Bulk Contacts"
                  color={theme.palette.primary.main}
                  iconPrimary={Import}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default AdressBook;
