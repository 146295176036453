import * as Yup from "yup";

import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  IconButton,
  Tooltip,
  // Tooltip,
  Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Form, Formik } from "formik";
import { createDestinationList, getDestinationList, updateDestinationList } from "store/reducers/setups";
import { useMemo, useState } from "react";

import Loader from "components/Loader";
import PropTypes from "prop-types";
// assets
import { dispatch } from "store";
import { openSnackbar } from "store/reducers/snackbar";
import { useSelector } from "store";
import useUserAuthInfo from "hooks/useAuthInfo";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useAdminClientId from "hooks/useAdminClientId";
import { Box, Trash } from "iconsax-react";

// ==============================|| CUSTOMER - ADD / EDIT ||============================== //

const AddDestination = ({ d, onCancel, isClient = false }) => {
  // const theme = useTheme();
  const destination = useMemo(() => d, [d]);
  const isCreating = !destination;
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useUserAuthInfo();
  const service = useSelector((state) => state.options.services);
  const { targetId } = useAdminClientId();

  const schema = Yup.object().shape({
    service: Yup.string().required("Service is required"),
    description: Yup.string().required("Description is required"),
    startdate: Yup.string(),
    enddate: Yup.string(),
    isactive: Yup.boolean(),
    charge: Yup.number(),
    tax: Yup.number(),
    clientid: Yup.number(),
    prefixsurfix: Yup.string().required("Prefix Surfix is required"),
    routecode: Yup.string().required("Route Code is required")
  });

  if (isLoading) return <Loader />;

  return (
    <>
      <DialogTitle>{destination ? "Edit Destination" : "Add New Destination"}</DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 2.5 }}>
        <Formik
          enableReinitialize
          initialValues={{
            charge: destination?.charge ?? 0,
            // clientid: targetId,
            description: destination?.description ?? "",
            enddate: destination?.enddate ?? dayjs().format("YYYY-MM-DD"),
            prefixsurfix: destination?.prefixsurfix ?? "",
            routecode: destination?.routecode ?? "",
            startdate: destination?.startdate ?? dayjs().format("YYYY-MM-DD"),
            tax: destination?.tax ?? 0,
            service: destination?.service ?? "",
            isactive: destination?.isactive ?? true
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            setIsLoading(true);
            const newDestination = {
              charge: values.charge,
              clientid: isClient ? targetId : null,
              description: values.description,
              enddate: values.enddate.includes("Invalid") ? null : values.enddate,
              isactive: values.isactive,
              prefixsurfix: values.prefixsurfix,
              routecode: values.routecode,
              startdate: values.startdate.includes("Invalid") ? null : values.startdate,
              tax: values.tax,
              service: values.service
            };

            if (destination) {
              updateDestinationList(token, destination.id, newDestination)
                .then(() => {
                  dispatch(getDestinationList(token, 0, 20, "", isClient ? targetId : null));
                  dispatch(
                    openSnackbar({
                      open: true,
                      message: "Destination updated successfully.",
                      variant: "alert",
                      alert: {
                        color: "success"
                      },
                      close: true
                    })
                  );
                  setIsLoading(false);
                  setSubmitting(false);
                  resetForm();
                  onCancel();
                })
                .catch((err) => {
                  console.error(err);
                  setIsLoading(false);
                  setSubmitting(false);
                  dispatch(
                    openSnackbar({
                      open: true,
                      message: "Error updating destination. Please try again.",
                      variant: "alert",
                      alert: {
                        color: "success"
                      },
                      close: true
                    })
                  );
                });
            } else {
              createDestinationList(token, newDestination)
                .then(() => {
                  dispatch(getDestinationList(token, 0, 20, "", isClient ? targetId : null));
                  dispatch(
                    openSnackbar({
                      open: true,
                      message: "Destination added successfully.",
                      variant: "alert",
                      alert: {
                        color: "success"
                      },
                      close: true
                    })
                  );
                  setIsLoading(false);
                  setSubmitting(false);
                  resetForm();
                  onCancel();
                })
                .catch((err) => {
                  console.error(err);
                  setIsLoading(false);
                  setSubmitting(false);
                  dispatch(
                    openSnackbar({
                      open: true,
                      message: "Error adding destination. Please try again.",
                      variant: "alert",
                      alert: {
                        color: "success"
                      },
                      close: true
                    })
                  );
                });
            }
          }}
        >
          {({ values, errors, touched, isSubmitting, getFieldProps, setFieldValue, resetForm }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="destination-charge">Service</InputLabel>
                    <Select
                      id="column-hiding"
                      displayEmpty
                      name="service"
                      {...getFieldProps("service")}
                      onChange={(event) => setFieldValue("service", event.target.value)}
                      input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <Typography variant="subtitle1">Select Service </Typography>;
                        }

                        return <Typography variant="subtitle2">{selected}</Typography>;
                      }}
                    >
                      {service.map((column) => (
                        <MenuItem key={column} value={column}>
                          <ListItemText primary={column} />
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.service && errors.service && (
                      <Typography variant="caption" color="error">
                        {errors.service}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="destination-description">Description</InputLabel>
                    <TextField
                      fullWidth
                      name="description"
                      id="destination-description"
                      {...getFieldProps("description")}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="destination-startdate">Start Date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="startdate"
                        minDate={isCreating ? dayjs() : null}
                        value={dayjs(values.startdate)}
                        onChange={(date) => {
                          setFieldValue("startdate", dayjs(date).format("YYYY-MM-DD"));
                        }}
                      />
                    </LocalizationProvider>
                    <FormHelperText error={Boolean(touched.startdate && errors.startdate)}>
                      {touched.startdate && errors.startdate}
                    </FormHelperText>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="destination-enddate">End Date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="enddate"
                        minDate={isCreating ? dayjs() : null}
                        value={dayjs(values.enddate)}
                        onChange={(date) => {
                          setFieldValue("enddate", dayjs(date).format("YYYY-MM-DD"));
                        }}
                      />
                    </LocalizationProvider>
                    <FormHelperText error={Boolean(touched.enddate && errors.enddate)}>{touched.enddate && errors.enddate}</FormHelperText>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="destination-charge">Charge</InputLabel>
                    <TextField
                      fullWidth
                      name="charge"
                      type="number"
                      id="destination-charge"
                      {...getFieldProps("charge")}
                      error={Boolean(touched.charge && errors.charge)}
                      helperText={touched.charge && errors.charge}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="destination-tax">Tax</InputLabel>
                    <TextField
                      fullWidth
                      name="tax"
                      type="number"
                      id="destination-tax"
                      {...getFieldProps("tax")}
                      error={Boolean(touched.tax && errors.tax)}
                      helperText={touched.tax && errors.tax}
                    />
                  </Stack>
                </Grid>{" "}
                {/* Clients Field */}
                {/* <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <Autocomplete
                      disableClearable
                      selectOnFocus
                      fullWidth
                      clearOnBlur
                      autoHighlight
                      handleHomeEndKeys
                      inputValue={clients.find((option) => option.value === values.clientid)?.label}
                      value={clients.find((option) => option.value === values.clientid)}
                      id="clientid"
                      name="clientid"
                      options={clients}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => {
                        return option?.value === value?.value;
                      }}
                      onChange={(event, newValue) => {
                        setFieldValue("clientid", newValue.value);
                      }}
                      renderInput={(params) => (
                        <Stack spacing={1}>
                          <InputLabel htmlFor="clientid">Client</InputLabel>
                          <TextField
                            variant="outlined"
                            inputValue={clients.find((option) => option.value === values.clientid)?.label}
                            placeholder="Select Client"
                            // Set initial value here
                            {...params}
                          />
                        </Stack>
                      )}
                    />
                  </Stack>
                </Grid> */}
                <Grid item xs={12} md={4}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="destination-prefixsurfix">Prefix/Suffix</InputLabel>
                    <TextField
                      fullWidth
                      name="prefixsurfix"
                      id="destination-prefixsurfix"
                      {...getFieldProps("prefixsurfix")}
                      error={Boolean(touched.prefixsurfix && errors.prefixsurfix)}
                      helperText={touched.prefixsurfix && errors.prefixsurfix}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="destination-prefixsurfix">Route Code</InputLabel>
                    <TextField
                      fullWidth
                      id="destination-routecode"
                      name="routecode"
                      {...getFieldProps("routecode")}
                      error={Boolean(touched.routecode && errors.routecode)}
                      helperText={touched.routecode && errors.routecode}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="destination-prefixsurfix">Status</InputLabel>
                    <FormControlLabel
                      name="isactive"
                      checked={values.isactive}
                      {...getFieldProps("isactive")}
                      control={<Checkbox />}
                      label="Set Active"
                      labelPlacement="end"
                      sx={{ mr: "1px" }}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <DialogActions sx={{ p: 2.5 }}>
                <Divider />
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    {!isCreating && (
                      <Tooltip title="Delete Destination" placement="top">
                        <IconButton onClick={() => setOpenAlert(true)} size="large" color="error">
                          <Trash variant="Bold" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Button
                        color="error"
                        disabled={isSubmitting}
                        onClick={() => {
                          resetForm();
                          onCancel();
                        }}
                      >
                        Cancel
                      </Button>
                      {isSubmitting ? (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <CircularProgress size={30} />
                        </Box>
                      ) : (
                        <Button type="submit" variant="contained" disabled={isSubmitting}>
                          {destination ? "Edit" : "Add"}
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </>
  );
};

AddDestination.propTypes = {
  d: PropTypes.object,
  onCancel: PropTypes.func,
  users: PropTypes.any,
  isClient: PropTypes.bool
};

export default AddDestination;
