import * as Yup from "yup";

// material-ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from "@mui/material";
import { Form, Formik } from "formik";
import { approveSenderId, getAdminSenderIds } from "store/reducers/messaging";

import Loader from "components/Loader";
import { PopupTransition } from "components/@extended/Transitions";
// import PropTypes from "prop-types";
import { Stack } from "@mui/system";
import { dispatch } from "store";
import { openSnackbar } from "store/reducers/snackbar";
import { useState } from "react";

// import { useTheme } from "@mui/material/styles";

// assets

// import { useState } from "react";

// project-imports

// ==============================|| SENDERID - PREVIEW ||============================== //

export default function ReviewId({ token, status, senderid, open, onClose }) {
  //   const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down("md"));
  // const [openAlert, setOpenAlert] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  //   const [add, setAdd] = useState(false);

  // const handleAdd = () => {
  //   setAdd(!add);
  // };

  // const handleClose = () => {
  //   setOpenAlert(!openAlert);
  //   onClose();
  // };

  {
    isLoading && <Loader />;
  }

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        TransitionComponent={PopupTransition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{ "& .MuiDialog-paper": { p: 0 }, transition: "transform 225ms" }}
      >
        <Box id="PopupPrint" sx={{ px: { xs: 2, sm: 3, md: 5 }, py: 1 }}>
          <DialogTitle sx={{ px: 0 }}>Configure SenderID</DialogTitle>
          <DialogContent dividers sx={{ px: 0 }}>
            <Formik
              initialValues={{
                status: senderid?.status || "",
                comment: ""
              }}
              validationSchema={Yup.object().shape({
                status: Yup.string(),
                comment: Yup.string()
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                setIsLoading(true);
                const data = {
                  status: values.status,
                  comment: values.comment
                };
                approveSenderId(token, senderid.id, data)
                  .then(() => {
                    dispatch(getAdminSenderIds(token)).then(() => setIsLoading(false));
                    dispatch(
                      openSnackbar({
                        open: true,
                        message: "Status Changed Successfully",
                        variant: "alert",
                        alert: {
                          color: "success"
                        },
                        close: true
                      })
                    );
                    setSubmitting(false);
                    onClose();
                  })
                  .catch((err) => {
                    console.error(err);
                    setIsLoading(false);
                    setSubmitting(false);
                    dispatch(
                      openSnackbar({
                        open: true,
                        message: "Error while changing status. Please try again",
                        variant: "alert",
                        alert: {
                          color: "error"
                        },
                        close: true
                      })
                    );
                  });
              }}
            >
              {({ values, handleBlur, handleChange, isSubmitting, getFieldProps }) => (
                <Form>
                  <Stack
                    spacing={1.25}
                    sx={{
                      marginBottom: "1rem"
                    }}
                  >
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Typography variant="body1">Requested SenderID</Typography>
                      <Typography variant="subtitle1" color="cadetblue">
                        {senderid?.senderid}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="tax-taxes"> Approval Decision </InputLabel>
                        <FormControl>
                          <Select
                            id="column-hiding"
                            displayEmpty
                            input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                            {...getFieldProps("status")}
                            value={values.status}
                            onChange={handleChange}
                            renderValue={(selected) => {
                              if (!selected) {
                                return <Typography variant="body1"> Select </Typography>;
                              }
                              return <Typography variant="subtitle2">{selected}</Typography>;
                            }}
                          >
                            {status.map((column) => (
                              <MenuItem key={column} value={column} selected={column === values.status}>
                                <ListItemText primary={column} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="tax-taxes"> Comment </InputLabel>
                        <FormControl>
                          <OutlinedInput
                            multiline
                            maxRows={10}
                            id="comment"
                            {...getFieldProps("comment")}
                            value={values.comment}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                      </Stack>
                    </Grid>
                  </Grid>
                  <DialogActions sx={{ p: 2.5 }}>
                    <Grid container justifyContent="end">
                      <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Button color="error" disabled={isSubmitting || isLoading} onClick={onClose}>
                            Cancel
                          </Button>
                          <Button type="submit" disabled={isSubmitting || isLoading} variant="contained">
                            Save
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}

// ReviewId.propTypes = {
//   customer: PropTypes.object,
//   onClose: PropTypes.func,
//   open: PropTypes.bool,

//   status: PropTypes.array,
//   token: PropTypes.any
// };
