// assets
import { I24Support, Profile } from "iconsax-react";
// material-ui
import { Link, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { useState } from "react";

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <List component="nav" sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 32 } }}>
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(event) => handleListItemClick(event, 0)}
        component={Link}
        to="/user/account-settings/password"
      >
        <ListItemIcon>
          <Profile variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Account Settings" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
        <ListItemIcon>
          <I24Support variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText
          primary="Support"
          onClick={() => {
            {
              window.open("https://support.wigal.com.gh/", "_blank");
            }
          }}
        />
      </ListItemButton>
    </List>
  );
};

export default SettingTab;
