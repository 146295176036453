import AuthGuard from "utils/route-guard/AuthGuard";
import Loadable from "components/Loadable";
// project-imports
import MainLayout from "layout/MainLayout";
import { lazy } from "react";

// render - admin
const Dashboard = Loadable(lazy(() => import("pages/admin/dashboard")));

// render - bulk messages
// const SendMessage = Loadable(lazy(() => import("pages/bulk-messaging/send-message")));
const SendSMS = Loadable(lazy(() => import("pages/bulk-messaging/send-message/sms")));
const SendEmail = Loadable(lazy(() => import("pages/bulk-messaging/send-message/email")));
const SendVoice = Loadable(lazy(() => import("pages/bulk-messaging/send-message/voice")));
const SentMessages = Loadable(lazy(() => import("pages/bulk-messaging/sent-messages")));
const ScheduledMessages = Loadable(lazy(() => import("pages/bulk-messaging/scheduled-messages")));
const MessageSummary = Loadable(lazy(() => import("pages/bulk-messaging/summary")));
const AnniversaryAlerts = Loadable(lazy(() => import("pages/bulk-messaging/schedule-message")));
const AnniversaryVoice = Loadable(lazy(() => import("pages/bulk-messaging/schedule-message/schedule")));
const AudioLibrary = Loadable(lazy(() => import("pages/bulk-messaging/audio-library")));
const IvrCampaigns = Loadable(lazy(() => import("pages/bulk-messaging/ivr-campaigns")));
const SenderId = Loadable(lazy(() => import("pages/bulk-messaging/senderids")));
const ScheduledMessageSummary = Loadable(lazy(() => import("pages/bulk-messaging/schedule-message/schedule/summary")));

// render - address book
const Contacts = Loadable(lazy(() => import("pages/address-book/contacts")));
const ManageGroups = Loadable(lazy(() => import("pages/address-book/manage-groups/index")));
const GroupDetails = Loadable(lazy(() => import("pages/address-book/manage-groups/Details")));
const BulkImports = Loadable(lazy(() => import("pages/address-book/bulk-imports")));

// render - User Credits
const CreditInvoiceList = Loadable(lazy(() => import("pages/credits/invoices")));
const Bundle = Loadable(lazy(() => import("pages/credits/bundles")));
const Balance = Loadable(lazy(() => import("pages/credits/balance")));
const Profile = Loadable(lazy(() => import("pages/profile")));
const InvoiceDetails = Loadable(lazy(() => import("pages/credits/invoices/details")));
// const PaymentCallback = Loadable(lazy(() => import("pages/payment-callback")));

const Error500 = Loadable(lazy(() => import("pages/maintenance/error/500")));
const Error403 = Loadable(lazy(() => import("pages/maintenance/error/403")));
const SessionExpired = Loadable(lazy(() => import("pages/maintenance/error/sessionexpired")));
const AccountSettings = Loadable(lazy(() => import("pages/account-settings")));
const ClientPassword = Loadable(lazy(() => import("pages/account-settings/password")));

const ClientRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard allowedRoles={["CLIENT", "ADMIN", "STAFF"]}>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        // {
        //   path: "payment-callback/",
        //   element: <PaymentCallback />
        // },
        {
          path: "dashboard",
          element: <Dashboard />
        },
        {
          path: "bulk-messaging",
          children: [
            // {
            //   path: "send-message",
            //   element: <SendMessage />
            // },
            {
              path: "send-message",
              element: <SendSMS />
            },

            {
              path: "send-message/email",
              element: <SendEmail />
            },
            {
              path: "send-message/voice",
              element: <SendVoice />
            },

            // {
            //   path: "sms",
            //   element: <SendSMS />
            // },

            {
              path: "sent-messages",
              element: <SentMessages />
            },
            {
              path: "scheduled-messages",
              element: <ScheduledMessages />
            },
            {
              path: "summary",
              element: <MessageSummary />
            },
            {
              path: "schedule-a-message",
              element: <AnniversaryAlerts />
            },
            {
              path: "schedule-a-message/schedule",
              element: <AnniversaryVoice />
            },
            {
              path: "scheduled-a-messages/summary",
              element: <ScheduledMessageSummary />
            },
            {
              path: "audio-library",
              element: <AudioLibrary />
            },
            {
              path: "ivr-campaigns",
              element: <IvrCampaigns />
            },
            {
              path: "ivr-campaigns",
              element: <IvrCampaigns />
            },
            {
              path: "senderids",
              element: <SenderId />
            },
            {
              path: "senderids/:id",
              element: <SenderId />
            }
          ]
        },
        {
          path: "address-book",
          children: [
            {
              path: "contacts",
              element: <Contacts />
            },
            {
              path: "groups",
              element: <ManageGroups />
            },
            {
              path: "manage-group/:groupid",
              element: <GroupDetails />
            },
            {
              path: "bulk-imports",
              element: <BulkImports />
            }
          ]
        },
        {
          path: "credits",
          children: [
            {
              path: "balance",
              element: <Balance />
            },
            {
              path: "bundles",
              element: <Bundle />
            },
            {
              path: "invoices",
              element: <CreditInvoiceList />
            },
            {
              path: "invoices/view/:invoiceid",
              element: <InvoiceDetails />
            }
          ]
        },
        {
          path: "user",
          children: [
            {
              path: "profile",
              element: <Profile />
            },
            {
              path: "profile/:client_id",
              element: <Profile />
            },
            {
              path: "account-settings",
              element: <AccountSettings />,
              children: [
                {
                  path: "password",
                  element: <ClientPassword />
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: "403",
      element: <Error403 />
    },
    {
      path: "500",
      element: <Error500 />
    },
    {
      path: "session-expired",
      element: <SessionExpired />
    }
  ]
};

export default ClientRoutes;
