import { dispatch } from "store";
import { getClientById } from "store/reducers/users";
import { setTargetId } from "store/reducers/messaging";
import { useEffect } from "react";
import useLocalStorage from "./useLocalStorage";
import { useParams } from "react-router-dom";
import { useSelector } from "store";
import useUserAuthInfo from "./useAuthInfo";

export default function useAdminClientId() {
  const { clientid } = useParams();
  const adminclientID = Number(clientid);
  const { token } = useUserAuthInfo();

  const [adminClientId, setAdminClientId] = useLocalStorage("adminClientId", adminclientID);

  const client = useSelector((state) => state.usersmanagement.clientsById);
  const targetId = useSelector((state) => state.messaging.targetId);

  // map list to get client name from list using id

  useEffect(() => {
    if (adminClientId) {
      dispatch(setTargetId(adminClientId));
    }
  }, [adminClientId]);

  useEffect(() => {
    if (adminclientID) {
      setAdminClientId(adminclientID);
      dispatch(getClientById(token, adminclientID));
    } else {
      setAdminClientId(null);
    }
  }, [adminclientID]);

  return { targetId, client };
}
