import { checkPersonalizedSMS, getAdminSenderIds, getBannedSenderIds, getScheduleMessage, setMessageData } from "store/reducers/messaging";
import { dispatch, useSelector } from "store";
import { getAllUsers, getClients } from "store/reducers/users";
import { getBundles, getDestinationList, getInvoiceList } from "store/reducers/setups";
import { getGroupContacts, getGroups } from "store/reducers/addressbook";

import { get } from "lodash";
import { useState } from "react";
import useUserAuthInfo from "./useAuthInfo";

/// Group Pagination
export function useServerGroupPagination(itemsPerPage, targetId, token) {
  const [currentPage, setCurrentPage] = useState(0);
  const groups = useSelector((state) => state.contacts.groups);
  const data = get(groups, "data.content", []);
  let totalElements = get(groups, "data.totalElements", 0);
  const [tableLoading, setTableLoading] = useState(false);
  let maxPage = get(groups, "data.totalPages", 0);

  async function initDataFetch() {
    setTableLoading(true);
    dispatch(getGroups(token, targetId, currentPage, itemsPerPage, "")).then(() => {
      maxPage = get(groups, "data.totalPages", 0);
      totalElements = get(groups, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function next(keyword) {
    // const targetId = localStorage.getItem("adminClientId") || clientid;

    setTableLoading(true);
    setCurrentPage(currentPage + 1);
    dispatch(getGroups(token, targetId, currentPage, itemsPerPage, keyword)).then(() => {
      maxPage = get(groups, "data.totalPages", 0);
      totalElements = get(groups, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function prev(keyword) {
    setTableLoading(true);
    setCurrentPage(currentPage - 1);
    dispatch(getGroups(token, targetId, currentPage, itemsPerPage, keyword)).then(() => {
      maxPage = get(groups, "data.totalPages", 0);
      totalElements = get(groups, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function jump(page, keyword) {
    setTableLoading(true);
    setCurrentPage(page);
    dispatch(getGroups(token, targetId, page, itemsPerPage, keyword)).then(() => {
      maxPage = get(groups, "data.totalPages", 0);
      totalElements = get(groups, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function search(keyword) {
    setTableLoading(true);
    setCurrentPage(0);
    dispatch(getGroups(token, targetId, 0, itemsPerPage, keyword)).then(() => {
      maxPage = get(groups, "data.totalPages", 0);
      totalElements = get(groups, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  return { next, prev, jump, search, data, currentPage, maxPage, initDataFetch, tableLoading, totalElements };
}

/// Personalized SMS Preview Pagination
export function usePersonalizedSMSPagination(itemsPerPage) {
  const { token, clientid } = useUserAuthInfo();
  const [currentPage, setCurrentPage] = useState(0);
  const { messageData } = useSelector((state) => state.messaging);
  let totalElements = messageData.totalElements;
  const [tableLoading, setTableLoading] = useState(false);
  let maxPage = messageData.totalPages;
  const { senderid, type, contactType, groups, destinations, message, schedule, repeattype, repeattypevalue } = messageData;
  const payload = {
    senderid,
    type,
    ...(contactType === "group" && { groups }),
    ...(contactType !== "group" && { destinations }),
    message,
    schedule,
    repeattype,
    repeattypevalue
  };

  async function initDataFetch() {
    setTableLoading(true);
    const res = await checkPersonalizedSMS(token, clientid, payload, currentPage, itemsPerPage, "");
    dispatch(setMessageData({ ...messageData, contactList: _.get(res, "data.messagelist.content", []) }));
    maxPage = messageData.totalPages;
    totalElements = messageData.totalElements;
    setTableLoading(false);
  }

  async function jump(page, keyword) {
    setTableLoading(true);
    setCurrentPage(page);
    const res = await checkPersonalizedSMS(token, clientid, payload, page, itemsPerPage, keyword);
    dispatch(setMessageData({ ...messageData, contactList: _.get(res, "data.messagelist.content", []) }));
    maxPage = messageData.totalPages;
    totalElements = messageData.totalElements;
    setTableLoading(false);
  }

  async function search(keyword) {
    setTableLoading(true);
    setCurrentPage(0);
    const res = await checkPersonalizedSMS(token, clientid, payload, 0, itemsPerPage, keyword);
    dispatch(setMessageData({ ...messageData, contactList: _.get(res, "data.messagelist.content", []) }));
    maxPage = messageData.totalPages;
    totalElements = messageData.totalElements;
    setTableLoading(false);
  }

  return { jump, search, currentPage, maxPage, initDataFetch, tableLoading, totalElements };
}

/// Invoice Pagination
export function useInvoicePagination(itemsPerPage, targetId) {
  const { token, clientid } = useUserAuthInfo();
  let idToUse = targetId || clientid;

  const [currentPage, setCurrentPage] = useState(0);
  const invoiceData = useSelector((state) => state.setups.invoices);
  let totalElements = get(invoiceData, "totalElements", 0);
  let maxPage = get(invoiceData, "totalPages", 0);
  const [tableLoading, setTableLoading] = useState(false);

  function initDataFetch() {
    setTableLoading(true);
    dispatch(getInvoiceList(token, idToUse, 0, itemsPerPage, "")).then(() => {
      maxPage = get(invoiceData, "data.totalPages", 0);
      totalElements = get(invoiceData, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  function jump(page, keyword) {
    setTableLoading(true);
    setCurrentPage(page);
    dispatch(getInvoiceList(token, idToUse, page, itemsPerPage, keyword)).then(() => {
      maxPage = get(invoiceData, "data.totalPages", 0);
      totalElements = get(invoiceData, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  function search(keyword) {
    setTableLoading(true);
    setCurrentPage(0);
    dispatch(getInvoiceList(token, idToUse, 0, itemsPerPage, keyword)).then(() => {
      maxPage = get(invoiceData, "data.totalPages", 0);
      totalElements = get(invoiceData, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  return { jump, search, currentPage, maxPage, initDataFetch, tableLoading, totalElements, itemsPerPage };
}

/// Contacts Pagination
export function useContactsPagination(groupid, itemsPerPage, targetId) {
  const { token, clientid } = useUserAuthInfo();
  let idToUse = targetId || clientid;

  const [currentPage, setCurrentPage] = useState(0);
  const { groupContacts } = useSelector((state) => state.contacts);
  const [tableLoading, setTableLoading] = useState(false);
  let totalElements = get(groupContacts, "data.totalElements");
  let maxPage = get(groupContacts, "data.totalPages", 0);

  function initDataFetch() {
    setTableLoading(true);
    dispatch(getGroupContacts(token, idToUse, groupid, 0, itemsPerPage, "")).then(() => {
      maxPage = get(groupContacts, "data.totalPages", 0);
      totalElements = get(groupContacts, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  function jump(page, keyword) {
    setTableLoading(true);
    setCurrentPage(page);
    dispatch(getGroupContacts(token, idToUse, groupid, page, itemsPerPage, keyword)).then(() => {
      maxPage = get(groupContacts, "data.totalPages", 0);
      totalElements = get(groupContacts, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  function search(keyword) {
    setTableLoading(true);
    setCurrentPage(0);
    dispatch(getGroupContacts(token, idToUse, groupid, 0, itemsPerPage, keyword)).then(() => {
      maxPage = get(groupContacts, "data.totalPages", 0);
      totalElements = get(groupContacts, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  return { jump, search, currentPage, maxPage, initDataFetch, tableLoading, totalElements, itemsPerPage };
}

/// Scheduled Messages Pagination
export function useScheduledMsgPagination(itemsPerPage, targetId) {
  const { token } = useUserAuthInfo();
  let idToUse = targetId;

  const [currentPage, setCurrentPage] = useState(0);
  const scheduledMessages = useSelector((state) => state.messaging.schedulemessages);
  const [tableLoading, setTableLoading] = useState(false);
  let totalElements = get(scheduledMessages, "data.totalElements");
  let maxPage = get(scheduledMessages, "data.totalPages", 0);

  function initDataFetch() {
    setTableLoading(true);
    dispatch(getScheduleMessage(token, idToUse, 0, itemsPerPage, "")).then(() => {
      maxPage = get(scheduledMessages, "data.totalPages", 0);
      totalElements = get(scheduledMessages, "data.totalElements");
      setTableLoading(false);
    });
  }

  function jump(page, keyword) {
    setTableLoading(true);
    setCurrentPage(page);
    dispatch(getScheduleMessage(token, idToUse, page, itemsPerPage, keyword)).then(() => {
      maxPage = get(scheduledMessages, "data.totalPages", 0);
      totalElements = get(scheduledMessages, "data.totalElements");
      setTableLoading(false);
    });
  }

  function search(keyword) {
    setTableLoading(true);
    setCurrentPage(0);
    dispatch(getScheduleMessage(token, idToUse, 0, itemsPerPage, keyword)).then(() => {
      maxPage = get(scheduledMessages, "data.totalPages", 0);
      totalElements = get(scheduledMessages, "data.totalElements");
      setTableLoading(false);
    });
  }

  return { jump, search, currentPage, maxPage, initDataFetch, tableLoading, totalElements, itemsPerPage };
}

/////////////////  ADMIN ///////////////

/// All Users Pagination
export function useServerAdminAllUsersPagination(itemsPerPage, userType) {
  const { token } = useUserAuthInfo();
  const [currentPage, setCurrentPage] = useState(0);
  const bState = useSelector((state) => state.usersmanagement.allUsers);
  const data = get(bState, "data.content", []);
  let totalElements = get(bState, "totalElements", 0);
  const [tableLoading, setTableLoading] = useState(false);
  let maxPage = get(bState, "totalPages", 0);

  async function initDataFetch() {
    setTableLoading(true);
    dispatch(getAllUsers(token, userType, 0, itemsPerPage, "")).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function next(keyword) {
    setTableLoading(true);
    setCurrentPage(currentPage + 1);
    dispatch(getAllUsers(token, userType, currentPage + 1, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function prev(keyword) {
    setTableLoading(true);
    setCurrentPage(currentPage - 1);
    dispatch(getAllUsers(token, userType, currentPage - 1, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function jump(page, keyword) {
    setTableLoading(true);
    setCurrentPage(page);
    dispatch(getAllUsers(token, userType, page, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function search(keyword) {
    setTableLoading(true);
    setCurrentPage(0);
    dispatch(getAllUsers(token, userType, 0, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  return { next, prev, jump, search, data, currentPage, maxPage, initDataFetch, tableLoading, totalElements };
}

/// All Clients Pagination
export function useServerAdminClientsPagination(itemsPerPage) {
  const { token } = useUserAuthInfo();
  const [currentPage, setCurrentPage] = useState(0);
  const bState = useSelector((state) => state.usersmanagement.clients);
  const data = get(bState, "data.content", []);
  let totalElements = get(bState, "totalElements", 0);
  const [tableLoading, setTableLoading] = useState(false);
  let maxPage = get(bState, "totalPages", 0);

  async function initDataFetch() {
    setTableLoading(true);
    dispatch(getClients(token, 0, itemsPerPage, "")).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function next(keyword) {
    setTableLoading(true);
    setCurrentPage(currentPage + 1);
    dispatch(getClients(token, currentPage + 1, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function prev(keyword) {
    setTableLoading(true);
    setCurrentPage(currentPage - 1);
    dispatch(getClients(token, currentPage - 1, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function jump(page, keyword) {
    setTableLoading(true);
    setCurrentPage(page);
    dispatch(getClients(token, page, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function search(keyword) {
    setTableLoading(true);
    setCurrentPage(0);
    dispatch(getClients(token, 0, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  return { next, prev, jump, search, data, currentPage, maxPage, initDataFetch, tableLoading, totalElements };
}

/// Bundle Pagination
export function useServerAdminBundlePagination(itemsPerPage) {
  const { token } = useUserAuthInfo();
  const [currentPage, setCurrentPage] = useState(0);
  const bState = useSelector((state) => state.setups.bundles);
  const data = get(bState, "data.content", []);
  let totalElements = get(bState, "data.totalElements", 0);
  const [tableLoading, setTableLoading] = useState(false);
  let maxPage = get(bState, "data.totalPages", 0);

  async function initDataFetch(clientid) {
    setTableLoading(true);
    dispatch(getBundles(token, clientid, 0, itemsPerPage, "")).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function jump(page, keyword, clientid) {
    setTableLoading(true);
    setCurrentPage(page);
    dispatch(getBundles(token, clientid, page, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function search(keyword, clientid) {
    setTableLoading(true);
    setCurrentPage(0);
    dispatch(getBundles(token, clientid, 0, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  return { jump, search, data, currentPage, maxPage, initDataFetch, tableLoading, totalElements };
}

/// All SenderIds Pagination
export function useServerAdminSenderIdsPagination(itemsPerPage, status = null) {
  const { token } = useUserAuthInfo();
  const [currentPage, setCurrentPage] = useState(0);
  const bState = useSelector((state) => state.messaging.adminSenderids);
  const data = get(bState, "data.content", []);
  let totalElements = get(bState, "data.totalElements", 0);
  const [tableLoading, setTableLoading] = useState(false);
  let maxPage = get(bState, "data.totalPages", 0);

  async function initDataFetch() {
    setTableLoading(true);
    dispatch(getAdminSenderIds(token, 0, itemsPerPage, "", status || "")).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function next(keyword) {
    setTableLoading(true);
    setCurrentPage(currentPage + 1);
    dispatch(getAdminSenderIds(token, currentPage + 1, itemsPerPage, keyword, status || "")).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function prev(keyword) {
    setTableLoading(true);
    setCurrentPage(currentPage - 1);
    dispatch(getAdminSenderIds(token, currentPage - 1, itemsPerPage, keyword, status || "")).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function jump(page, keyword) {
    setTableLoading(true);
    setCurrentPage(page);
    dispatch(getAdminSenderIds(token, page, itemsPerPage, keyword, status || "")).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function search(keyword) {
    setTableLoading(true);
    setCurrentPage(0);
    dispatch(getAdminSenderIds(token, 0, itemsPerPage, keyword, status || "")).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  return { next, prev, jump, search, data, currentPage, maxPage, initDataFetch, tableLoading, totalElements };
}

/// All Users Pagination
export function useServerAdminUsersPagination(itemsPerPage, usertype) {
  const { token } = useUserAuthInfo();
  const [currentPage, setCurrentPage] = useState(0);
  const bState = useSelector((state) => state.usersmanagement.allUsers);
  const data = get(bState, "content", []);
  let totalElements = get(bState, "totalElements", 0);
  const [tableLoading, setTableLoading] = useState(false);
  let maxPage = get(bState, "totalPages", 0);

  async function initDataFetch() {
    setTableLoading(true);
    dispatch(getAllUsers(token, usertype, 0, itemsPerPage, "")).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function next(keyword) {
    setTableLoading(true);
    setCurrentPage(currentPage + 1);
    dispatch(getAllUsers(token, usertype, currentPage + 1, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function prev(keyword) {
    setTableLoading(true);
    setCurrentPage(currentPage - 1);
    dispatch(getAllUsers(token, usertype, currentPage - 1, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function jump(page, keyword) {
    setTableLoading(true);
    setCurrentPage(page);
    dispatch(getAllUsers(token, usertype, page, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function search(keyword) {
    setTableLoading(true);
    setCurrentPage(0);
    dispatch(getAllUsers(token, usertype, 0, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  return { next, prev, jump, search, data, currentPage, maxPage, initDataFetch, tableLoading, totalElements };
}

/// All Destination Pagination
export function useServerAdminDestinationPagination(itemsPerPage) {
  const { token } = useUserAuthInfo();
  const [currentPage, setCurrentPage] = useState(0);
  const bState = useSelector((state) => state.setups.destinations);
  const data = get(bState, "content", []);
  let totalElements = get(bState, "totalElements", 0);
  const [tableLoading, setTableLoading] = useState(false);
  let maxPage = get(bState, "totalPages", 0);

  async function initDataFetch(clientid) {
    setTableLoading(true);
    dispatch(getDestinationList(token, 0, itemsPerPage, "", clientid)).then(() => {
      console.log("🚀 ~ dispatch ~ bState:", bState)
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function jump(page, keyword, clientid) {
    setTableLoading(true);
    setCurrentPage(page);
    dispatch(getDestinationList(token, page, itemsPerPage, keyword, clientid)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  async function search(keyword, clientid) {
    setTableLoading(true);
    setCurrentPage(0);
    dispatch(getDestinationList(token, 0, itemsPerPage, keyword, clientid)).then(() => {
      maxPage = get(bState, "totalPages", 0);
      totalElements = get(bState, "totalElements", 0);
      setTableLoading(false);
    });
  }

  return { jump, search, data, currentPage, maxPage, initDataFetch, tableLoading, totalElements };
}

/// All BannerIDs Pagination
export function useServerAdminBannerIdsPagination(itemsPerPage) {
  const { token } = useUserAuthInfo();
  const [currentPage, setCurrentPage] = useState(0);
  const bState = useSelector((state) => state.messaging.bannedSenderIds);
  const data = get(bState, "data.content", []);
  let totalElements = get(bState, "data.totalElements", 0);
  const [tableLoading, setTableLoading] = useState(false);
  let maxPage = get(bState, "data.totalPages", 0);

  async function initDataFetch() {
    setTableLoading(true);
    dispatch(getBannedSenderIds(token, 0, itemsPerPage, "")).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function next(keyword) {
    setTableLoading(true);
    setCurrentPage(currentPage + 1);
    dispatch(getBannedSenderIds(token, currentPage + 1, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function prev(keyword) {
    setTableLoading(true);
    setCurrentPage(currentPage - 1);
    dispatch(getBannedSenderIds(token, currentPage - 1, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function jump(page, keyword) {
    setTableLoading(true);
    setCurrentPage(page);
    dispatch(getBannedSenderIds(token, page, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  async function search(keyword) {
    setTableLoading(true);
    setCurrentPage(0);
    dispatch(getBannedSenderIds(token, 0, itemsPerPage, keyword)).then(() => {
      maxPage = get(bState, "data.totalPages", 0);
      totalElements = get(bState, "data.totalElements", 0);
      setTableLoading(false);
    });
  }

  return { next, prev, jump, search, data, currentPage, maxPage, initDataFetch, tableLoading, totalElements };
}
