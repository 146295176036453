import * as Yup from "yup";

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Box,
  // Tooltip,
  Typography
} from "@mui/material";
import React, { useMemo, useState } from "react";

import { Form, Formik } from "formik";
import { useNavigate } from "react-router";
import Loader from "components/Loader";
import PropTypes from "prop-types";
import { createInvoiceList } from "store/reducers/setups";
import { dispatch } from "store";
import useUserAuthInfo from "hooks/useAuthInfo";
import { openSnackbar } from "store/reducers/snackbar";
const invoiceTypes = ["CREDIT_NOTE", "DEBIT_NOTE", "IOU"];

export default function GenerateInvoice({ onCancel, client, subClients }) {
  const { token } = useUserAuthInfo();
  const navigate = useNavigate();

  const clients = useMemo(() => {
    let list = subClients.map((client) => ({ id: client.id, label: client.name }));
    if (client) {
      list.unshift({ id: client.id, label: client.name });
    }
    return list;
  }, [subClients, client]);

  const schema = Yup.object().shape({
    invoicetype: Yup.string().required("Invoice Type is required"),
    amount: Yup.number().required("Amount is required"),
    description: Yup.string(),
    bundleid: Yup.number(),
    benefactorid: Yup.number()
  });
  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) return <Loader />;
  return (
    <>
      <DialogTitle>{`Generate Invoice for ${client?.name}`}</DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 2.5 }}>
        <Formik
          enableReinitialize
          initialValues={{
            invoicetype: invoiceTypes[0],
            amount: 0,
            description: "",
            bundleid: 0,
            benefactorid: clients[0]?.id || client?.id
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            setIsLoading(true);
            dispatch(
              createInvoiceList(token, client.id, {
                ...values,
                bundleid: null
              })
            )
              .then(() => {
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "Invoice generated successfully",
                    variant: "alert",
                    alert: {
                      color: "success"
                    },
                    close: true
                  })
                );
                setIsLoading(false);
                setSubmitting(false);
                resetForm();
                onCancel();
                navigate(`/admin/clients/credits/invoices/${client.id}`);
              })
              .catch(() => {
                setIsLoading(false);
                setSubmitting(false);
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "Could not create invoice. Please try again.",
                    variant: "alert",
                    alert: {
                      color: "error"
                    },
                    close: true
                  })
                );
              });
          }}
        >
          {({ values, errors, touched, isSubmitting, getFieldProps, setFieldValue, resetForm, handleChange }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="destination-charge">Invoice Type:</InputLabel>
                    <Select
                      id="invoicetype"
                      displayEmpty
                      name="invoicetype"
                      {...getFieldProps("invoicetype")}
                      onChange={(event) => setFieldValue("invoicetype", event.target.value)}
                      input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <Typography variant="subtitle1">Select Invoice Type</Typography>;
                        }

                        return <Typography variant="subtitle1">{selected}</Typography>;
                      }}
                    >
                      {invoiceTypes.map((column) => (
                        <MenuItem key={column} value={column}>
                          <ListItemText primary={column} />
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.service && errors.service && (
                      <Typography variant="caption" color="error">
                        {errors.service}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="destination-description">Description</InputLabel>
                    <TextField
                      fullWidth
                      name="description"
                      id="destination-description"
                      {...getFieldProps("description")}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="amount">Amount:</InputLabel>
                    <TextField
                      fullWidth
                      type="number"
                      name="amount"
                      id="amount"
                      value={values.amount}
                      onChange={handleChange}
                      // {...getFieldProps("invoiceValid")}
                      // error={Boolean(touched.invoicedaysvalid && errors.invoicedaysvalid)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="benefactorid">Benefactor:</InputLabel>
                    <Select
                      id="benefactorid"
                      displayEmpty
                      name="benefactorid"
                      {...getFieldProps("benefactorid")}
                      onChange={(event) => {
                        setFieldValue("benefactorid", event.target.value);
                      }}
                      value={values.benefactorid}
                      input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <Typography variant="subtitle1">{client?.name || "Select Benefactor"}</Typography>;
                        }
                        return (
                          <Typography variant="subtitle1">{_.filter(clients, (c) => c.id === values.benefactorid)[0]?.label}</Typography>
                        );
                      }}
                    >
                      {clients.map((column) => (
                        <MenuItem key={column?.id || client.id} value={column?.id || client?.id}>
                          <ListItemText primary={column?.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
              </Grid>

              <DialogActions sx={{ p: 2.5 }}>
                <Divider />
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item></Grid>
                  <Grid item>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Button
                        color="error"
                        disabled={isSubmitting}
                        onClick={() => {
                          resetForm();
                          onCancel();
                        }}
                      >
                        Cancel
                      </Button>
                      {isSubmitting ? (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <CircularProgress size={30} />
                        </Box>
                      ) : (
                        <Button type="submit" variant="contained" disabled={isSubmitting}>
                          Generate
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </>
  );
}

GenerateInvoice.propTypes = {
  onCancel: PropTypes.func,
  client: PropTypes.object,
  subClients: PropTypes.array
};
