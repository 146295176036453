import { Add, Trash } from "iconsax-react";
import { Button, Dialog, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery } from "@mui/material";
// assets
// import { Bill, CardCoin, Coin, Personalcard, Share, Tag2, UserOctagon } from "iconsax-react";
import {
  EmptyTable,
  HeaderSort,
  IndeterminateCheckbox,
  SortingSelect,
  TablePagination,
  TableRowSelection,
  TableLoading
} from "components/third-party/ReactTable";
import { Fragment, useEffect, useMemo, useState } from "react";
import { GlobalFilter, renderFilterTypes } from "utils/react-table";
// material-ui
import { alpha, useTheme } from "@mui/material/styles";
import { deleteBannedSenderId, getAdminSenderIds, getBannedSenderIds } from "store/reducers/messaging";
import { getAllUsers, getClients } from "store/reducers/users";
import { useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";

import AddSenderId from "../addSenderId";
import AlertDelete from "components/AlertDelete";
import IconButton from "components/@extended/IconButton";
import { LOGOUT } from "store/reducers/actions";
import Loader from "components/Loader";
// project-imports
import MainCard from "components/MainCard";
import { PopupTransition } from "components/@extended/Transitions";
// third-party
// import { PopupTransition } from "components/@extended/Transitions";
import PropTypes from "prop-types";
// import ReviewId from "../ReviewId";
import ScrollX from "components/ScrollX";
import { ThemeMode } from "config";
import { dispatch } from "store";
import { format } from "date-fns";
import { get } from "lodash";
import { getSenderIdStatus } from "store/reducers/options";
import { openSnackbar } from "store/reducers/snackbar";
// import useAuth from "hooks/useAuth";
import { useSelector } from "store";
import useUserAuthInfo from "hooks/useAuthInfo";
import { useServerAdminBannerIdsPagination } from "hooks/useServerPagination";

// import { useNavigate } from "react-router";

// import makeData from "data/react-table";

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, _DATA, data, renderRowSubComponent, handleAdd }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const filterTypes = useMemo(() => renderFilterTypes, []);
  const sortBy = { id: "senderid", desc: true };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // setHiddenColumns,
    allColumns,
    visibleColumns,
    page,
    state: { selectedRowIds, expanded },
    preGlobalFilteredRows,
    setSortBy
    // selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ["id"], sortBy: [sortBy] }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  // useEffect(() => {
  //   if (matchDownSM) {
  //     setHiddenColumns(["age", "contact", "visits", "email", "status", "avatar"]);
  //   } else {
  //     setHiddenColumns(["avatar", "email"]);
  //   }
  //   // eslint-disable-next-line
  // }, [matchDownSM]);
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <>
      <TableRowSelection selected={Object.keys(selectedRowIds).length} />
      <Stack spacing={3}>
        <Stack
          direction={matchDownSM ? "column" : "row"}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: 0 }}
        >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={searchTerm}
            setGlobalFilter={(e) => {
              setSearchTerm(e);
              // Delay for 500ms
              setTimeout(() => {
                if ((e?.toString().length || -1) > 0) {
                  _DATA.search(e.toLowerCase());
                } else {
                  _DATA.search("");
                }
              }, 500);
            }}
          />
          {/* <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} /> */}
          <Stack direction={matchDownSM ? "column" : "row"} alignItems="center" spacing={2}>
            <SortingSelect sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} />
            <Button variant="contained" size="large" startIcon={<Add />} onClick={handleAdd}>
              Ban A Sender Id
            </Button>
            {/* <CSVExport data={selectedFlatRows.length > 0 ? selectedFlatRows.map((d) => d.original) : data} filename={"senderid-list.csv"} /> */}
          </Stack>
        </Stack>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ "& > th:first-of-type": { width: "58px" } }}>
                {headerGroup.headers.map((column) => (
                  <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                    <HeaderSort column={column} sort />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {_DATA.tableLoading ? (
              <TableLoading colSpan={headerGroups[0].headers.length} />
            ) : page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();

                return (
                  <Fragment key={i}>
                    <TableRow
                      {...row.getRowProps()}
                      onClick={() => {
                        row.toggleRowSelected();
                      }}
                      sx={{ cursor: "pointer", bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : "inherit" }}
                    >
                      {row.cells.map((cell) => (
                        <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                  </Fragment>
                );
              })
            ) : (
              <EmptyTable msg="No Data" colSpan={9} />
            )}
            <TableRow sx={{ "&:hover": { bgcolor: "transparent !important" } }}>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination
                  gotoPage={(p) => {
                    _DATA.jump(p, searchTerm.toLowerCase());
                  }}
                  totalPages={_DATA.maxPage}
                  disablePaging={true}
                  pageIndex={_DATA.currentPage}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  getHeaderProps: PropTypes.func,
  handleAdd: PropTypes.func,
  _DATA: PropTypes.object,
  renderRowSubComponent: PropTypes.any
};

// ==============================|| BANNED SENDER ID - LIST ||============================== //

const BanSenderIds = () => {
  const { token, userid, name } = useUserAuthInfo();

  const theme = useTheme();
  const mode = theme.palette.mode;
  // const adminSenderids = useSelector((state) => state.messaging.adminSenderids);
  const bannedSenderIds = useSelector((state) => state.messaging.bannedSenderIds);
  const { clients, allUsers } = useSelector((state) => state.usersmanagement);
  // const senderidStatus = useSelector((state) => state.options.senderidStatus);

  const clientList = useMemo(() => get(clients, "content", []), [clients]);
  const usersList = useMemo(() => get(allUsers, "content", []), [allUsers]);
  console.log("🚀 ~ BanSenderIds ~ usersList:", usersList);

  const Bannedlist = get(bannedSenderIds, "data.content", []);

  //   console.log("🚀 ~ BanSenderIds ~ bannedSenderIds:", bannedSenderIds);

  const data = useMemo(() => Bannedlist, [Bannedlist]);

  const [open, setOpen] = useState(false);
  const [senderid, setSenderId] = useState(null);
  const [add, setAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedSenderId, setSelectedSenderId] = useState(null);
  const _DATA = useServerAdminBannerIdsPagination(20);
  useEffect(() => {
    const usertype = "STAFF";
    dispatch(getAdminSenderIds(token));
    dispatch(getAllUsers(token, usertype));
    dispatch(getSenderIdStatus(token));
    dispatch(getClients(token))
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.status === "PERMISSION_DENIED") {
          dispatch({ type: LOGOUT });
        }
      });
    _DATA.initDataFetch().then(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setAdd(!add);
    if (senderid && !add) setSenderId(null);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleDelete = () => {
    dispatch(deleteBannedSenderId(token, senderid.senderid)).then(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: "Sender ID Deleted",
          variant: "alert",
          alert: {
            color: "error"
          },
          close: false
        })
      );
      dispatch(getBannedSenderIds(token));
      setAdd(false);
    });
  };

  // const handleReview = (selectedData) => {
  //   setSelectedSenderId(selectedData);
  //   setOpen(!open);
  // };

  const columns = useMemo(
    () => [
      {
        title: "Row Selection",
        Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />,
        accessor: "selection",
        Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
        disableSortBy: true
      },
      {
        Header: " ID",
        accessor: "id"
      },

      {
        Header: "Sender ID",
        accessor: "senderid"
      },

      // {
      //   Header: "Client",
      //   accessor: "clientid",
      //   Cell: ({ value }) => {
      //     const client = clientList.find((item) => item.id === value);
      //     return client ? client.name : "N/A";
      //   }
      // },
      {
        Header: "Date Submitted",
        accessor: "createdat",
        Cell: ({ value }) => {
          return format(new Date(value), "MMMM d, yyyy");
        }
      },
      {
        Header: "Created By",
        accessor: "createdby",
        Cell: ({ value }) => {
          if (value === userid) return name;
          const user = usersList.find((item) => item.id === value);
          return user ? user.name : "N/A";
        }
      },
      // {
      //   Header: " Active",
      //   accessor: "isactive",
      //   Cell: ({ value }) => {
      //     switch (value) {
      //       case false:
      //         return <Chip color="error" label="inacive" size="small" variant="light" />;
      //       case true:
      //         return <Chip color="success" label="active" size="small" variant="light" />;
      //       default:
      //     }
      //   }
      // },
      // {
      //   Header: " Status",
      //   accessor: "status",
      //   Cell: ({ value }) => {
      //     if (value === "PENDING") {
      //       return <Chip color="warning" label="pending" size="small" variant="light" />;
      //     } else {
      //       return null;
      //     }
      //   }
      // },
      {
        Header: "Actions",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" spacing={0}>
              {/* <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                      opacity: 0.9
                    }
                  }
                }}
                title="Review Banned Sender ID"
              >
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    // setPlan(row.values);
                    handleReview(row.values);
                  }}
                >
                  <Eye />
                </IconButton>
              </Tooltip> */}
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                      opacity: 0.9
                    }
                  }
                }}
                title="Delete"
              >
                <IconButton
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSenderId(row.values);
                    handleAdd();
                  }}
                >
                  <Trash />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme, clientList, usersList]
  );

  // const renderRowSubComponent = useCallback(({ row }) => <CustomerView data={data[Number(row.id)]} />, [data]);

  if (isLoading) return <Loader />;

  return (
    <MainCard content={false}>
      <ScrollX>
        <ReactTable _DATA={_DATA} columns={columns} data={data} handleAdd={handleAdd} />
      </ScrollX>
      {/* <ReviewId token={token} status={senderidStatus} senderid={selectedSenderId} open={open} onClose={handleClose} /> */}
      <Dialog
        maxWidth="sm"
        TransitionComponent={PopupTransition}
        keepMounted
        onClose={handleAdd}
        open={add}
        sx={{ "& .MuiDialog-paper": { p: 0 }, transition: "transform 225ms" }}
        aria-describedby="alert-dialog-slide-description"
      >
        <AddSenderId token={token} senderid={senderid} onCancel={handleAdd} banned />
      </Dialog>{" "}
      <AlertDelete title={senderid?.senderid} open={open} handleClose={handleClose} handleDelete={handleDelete} />
    </MainCard>
  );
};

BanSenderIds.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  avatar: PropTypes.object,
  message: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  id: PropTypes.number
};

export default BanSenderIds;
