import { Container, Grid } from "@mui/material";
import { Monero, MoneyRecive } from "iconsax-react";

import ClientCTA from "./ClientCTA";
import Template from "./template";
import useAdminClientId from "hooks/useAdminClientId";
import { useTheme } from "@mui/material/styles";

// import { useParams } from "react-router";

// import userStats1 from "assets/images/widget/userstats1.svg";
// import userStats2 from "assets/images/widget/userstats2.svg";
// import userStats3 from "assets/images/widget/userstats3.svg";
// import userStats4 from "assets/images/widget/userstats4.svg";

const ClientSettings = () => {
  const theme = useTheme();
  const { targetId, client } = useAdminClientId();

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ClientCTA title="Settings" showDo user={client ? client?.name : "..."} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <Template
                  link={`/admin/clients/settings/bundles/${targetId}`}
                  primary="Bundles"
                  color={theme.palette.primary.main}
                  iconPrimary={Monero}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <Template
                  link={`/admin/clients/settings/destinations/${targetId}`}
                  primary="Destinations"
                  color={theme.palette.primary.main}
                  iconPrimary={MoneyRecive}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default ClientSettings;
