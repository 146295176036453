// material-ui
import { Grid, Stack, Typography } from "@mui/material";

import { Link } from "react-router-dom";
// project-imports
import MainCard from "components/MainCard";
import PropTypes from "prop-types";

// ==============================|| STATISTICS - REPORT CARD ||============================== //

const Template = ({ primary, iconPrimary, color, link, clickFn, isLink = true }) => {
  const IconPrimary = iconPrimary;
  const primaryIcon = iconPrimary ? <IconPrimary size={30} color={color} /> : null;

  return isLink ? (
    <Link to={link} style={{ textDecoration: "none" }}>
      <MainCard>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Stack spacing={0.25}>
              <Typography variant="h5">{primary}</Typography>
            </Stack>
          </Grid>
          <Grid item>{primaryIcon}</Grid>
        </Grid>
      </MainCard>
    </Link>
  ) : (
    <MainCard onClick={clickFn}>
      <Grid
        style={{
          hover: {
            cursor: "pointer"
          }
        }}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Stack spacing={0.25}>
            <Typography variant="h5">{primary}</Typography>
          </Stack>
        </Grid>
        <Grid item>{primaryIcon}</Grid>
      </Grid>
    </MainCard>
  );
};

Template.propTypes = {
  link: PropTypes.string,
  clickFn: PropTypes.func,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  iconPrimary: PropTypes.object,
  color: PropTypes.string,
  isLink: PropTypes.bool
};

export default Template;
