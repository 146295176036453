// import { Chance } from "chance";
import axios from "utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

// const chance = new Chance();

// initial state
export const initialState = {
  error: null,
  uploadedContact: [],
  contacts: [],
  groups: [],
  activeSims: null,
  selectedGroup: null,
  groupContacts: []
};

const addressbook = createSlice({
  name: "addressbook",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // active sims

    getActiveSimsSuccess(state, action) {
      state.activeSims = action.payload;
    },

    // get contacts
    setContactSuccess(state, action) {
      const list = get(action, "payload.data.content", []);
      state.contacts = list;
    },

    // add contacts
    addContactSuccess(state, action) {
      state.contacts.push(action.payload);
    },

    // get uploaded contacts
    setUploadedContacts(state, action) {
      state.uploadedContact = action.payload;
    },

    addUploadedContacts(state, action) {
      // spreed state and add new contact
      state.uploadedContact = [...state.uploadedContact, action.payload];
    },

    // !* GROUPS

    // get groups
    setGroupsSuccess(state, action) {
      state.groups = action.payload;
    },

    // get selected goup
    setSelectedGroup(state, action) {
      state.selectedGroup = action.payload;
    },

    // add group
    addGroup(state, action) {
      state.groups.push(action.payload);
    },

    updateGroup(state, action) {
      const { id, name, status } = action.payload;
      return {
        ...state,
        groups: state.groups.map((group) => (group.id === id ? { ...group, name, status } : group))
      };
    },

    deleteGroup(state, action) {
      const { id } = action.payload;
      const index = state.groups.findIndex((group) => group.id === id);
      if (index !== -1) {
        state.groups.splice(index, 1);
      }
    },

    setGroupContacts(state, action) {
      // const contacts = get(action, "payload.data.content", []);
      // console.log("🚀 ~ setGroupContacts ~ contacts:", contacts);
      state.groupContacts = action.payload;
    },

    deleteGroupContact(state, action) {
      const { id } = action.payload;
      const index = state.groupContacts.findIndex((group) => group.id === id);
      // console.log("🚀 ~ deleteGroupContact ~ index:", index);
      if (index !== -1) {
        state.groupContacts.splice(index, 1);
      }
    },

    updateGroupContact(state, action) {
      const { id, name, msisdn, email, date, custom1, custom2 } = action.payload;
      return {
        ...state,
        groupContacts: state.groupContacts.map((contact) =>
          contact.id === id ? { ...contact, name, msisdn, email, date, custom1, custom2 } : contact
        )
      };
    }
  }
});

// Reducer
export default addressbook.reducer;

export const {
  hasError,
  setContactSuccess,
  addContactSuccess,
  addUploadedContacts,
  setUploadedContacts,
  addGroup,
  setGroupsSuccess,
  updateGroup,
  deleteGroup,
  setSelectedGroup,
  setGroupContacts,
  updateGroupContact,
  deleteGroupContact,
  getActiveSimsSuccess
} = addressbook.actions;

export async function getActiveSims(token, clientid, groupid, params, allow) {
  const response = await axios.get(`/addressbook/${clientid}/group/${groupid}/simactive?${params}=${allow}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export function getContacts() {
  return async (dispatch) => {
    try {
      const response = await axios.get("https://65c7e18ee7c384aada6f0b79.mockapi.io/api/v1/contacts");
      dispatch(setContactSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export async function createContact(token, clientid, groupid, contact) {
  // console.log("🚀 ~ createContact ~ groupid:", groupid);

  const response = await axios.post(`/addressbook/${clientid}/group/${groupid}/contact`, contact, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;

}

export function getUploadedContactss() {
  return async (dispatch) => {
    try {
      const response = await axios.get("https://65c7e18ee7c384aada6f0b79.mockapi.io/api/v1/contacts");
      dispatch(setUploadedContacts(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createUploadedContacts(contact) {
  return async (dispatch) => {
    try {
      const response = await axios.post("https://65c7e18ee7c384aada6f0b79.mockapi.io/api/v1/contacts", contact);
      dispatch(addUploadedContacts(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

//  !* GROUPS API CALLS
export function getGroups(token, clientid, page = 0, pageSize = 1000, search = "") {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/addressbook/${clientid}/group?search=${search}&page=${page}&size=${pageSize}&sort=id,desc`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(setGroupsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Creates a new group in the address book.
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {string} groupname - The name of the group.
 * @param {string} parentid - The ID of the parent group.
 * @returns {Promise<Object>} - A promise that resolves to the response data.
 */
export async function createGroup(token, clientid, groupname, parentid) {
  // console.log("🚀 ~ createGroup ~ data", data.clientid);
  const payload = {
    groupname: groupname,
    parentid: parentid
  };

  const response = await axios.post(`/addressbook/${clientid}/group`, payload, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Deletes a group by its ID.
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {string} id - The ID of the group to delete.
 * @returns {Promise} A promise that resolves to the response data.
 */
export async function deleteGroupById(token, clientid, id) {
  const response = await axios.delete(`/addressbook/${clientid}/group/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export async function updateGroupById(id, token, clientid, groupname, parentid) {
  const payload = {
    groupname: groupname,
    parentid: parentid
  };
  const response = await axios.put(`/addressbook/${clientid}/group/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

export function getGroupContacts(token, clientid, groupid, page = 0, size = 50, search = "") {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/addressbook/${clientid}/group/${groupid}/contactlist`, {
        params: {
          page: page,
          size: size,
          search,
          sort: "name,asc"
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(setGroupContacts(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export async function deleteGroupContactById(token, clientid, groupid, contactid) {
  const response = await axios.delete(`/addressbook/${clientid}/group/${groupid}/contact/${contactid}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export async function deleteBulkContacts(token, clientid, groupid, deleteIds) {
  // console.log("🚀 ~ deleteBulkContacts ~ deleteIds:", deleteIds);
  const response = await axios.delete(`/addressbook/${clientid}/group/${groupid}/contact`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
    data: deleteIds // Pass deleteIds as data
  });
  return response.data;
}

export async function updateGroupContactById(token, clientid, contactid, data) {

  const res = await axios.put(`/addressbook/${clientid}/contact/${contactid}`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;

}

export async function postBulkContacts(token, clientid, groupid, data) {
  try {
    const response = await axios.post(`/addressbook/${clientid}/group/${groupid}/contactlist`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error validating uploaded contacts:", error);
    throw error; // Re-throw the error to be handled by the caller
  }
}

export async function validateUploadedContacts(token, data) {
  try {
    const response = await axios.post(`/addressbook/msisdn/validate`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    // Handle any errors here
    console.error("Error validating uploaded contacts:", error);
    throw error; // Re-throw the error to be handled by the caller
  }
}
