import * as Yup from "yup";

import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Stack,
  TextField
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { addBannedSenderId, createSenderId, getAdminSenderIds, getBannedSenderId, updateSenderId } from "store/reducers/messaging";

// import AlertDelete from "components/AlertDelete";
// import { Chance } from "chance";
// project-imports
// import IconButton from "components/@extended/IconButton";
import Loader from "components/Loader";
import PropTypes from "prop-types";
// assets
// import { Trash } from "iconsax-react";
import { dispatch } from "store";
// import { ThemeMode } from "config";
// third-party
import { merge } from "lodash";
import { openSnackbar } from "store/reducers/snackbar";
import { useState } from "react";

// import useAuth from "hooks/useAuth";

// const chance = new Chance();

// material-ui
// import { useTheme } from "@mui/material/styles";

// constant;
const getInitialValues = (senderid) => {
  const senderId = {
    senderid: "",
    isactive: false
  };

  if (senderid) {
    senderId.senderid = senderid?.senderid;
    senderId.isactive = senderid?.isactive;

    return merge({}, senderId, senderid);
  }

  return senderId;
};

// ==============================|| SENDERID - ADD / EDIT ||============================== //

const AddSenderId = ({ token, senderid, onCancel, banned }) => {
  //   console.log("🚀 ~ AddSenderId ~ senderid:", senderid);
  //   const { user } = useAuth();
  //   const senderid.clientid = useMemo(() => get(user, "data[0].useraccount.senderid.clientid"), [user]);
  //   const token = useMemo(() => get(user, "data[0].accessToken.token"), [user]);
  // const theme = useTheme();
  // const isCreating = !senderid;
  const [isLoading, setIsLoading] = useState(false);

  //    const userOptions = map(clients, (user) => ({
  //      value: user.id,
  //      label: user.name
  //    }));

  const schema = Yup.object().shape({
    senderid: Yup.string()
      .min(4, "Sender ID must be at least 4 characters")
      .max(11, "Sender ID must be at most 11 characters")
      .required("Sender ID is required")
      .matches(/^[a-zA-Z0-9]+$/, "Enter sender ID without spacing and special characters"),
    isactive: Yup.boolean()
  });

  // const [openAlert, setOpenAlert] = useState(false);

  // const handleAlertClose = () => {
  //   setOpenAlert(!openAlert);
  //   onCancel();
  // };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(senderid),
    validationSchema: schema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const senderId = {
          senderid: values.senderid,
          isactive: values.isactive
        };

        setIsLoading(true);
        const createFunction = banned ? addBannedSenderId : createSenderId;
        const getIds = banned ? getBannedSenderId : getAdminSenderIds;

        if (senderid) {
          //Update
          dispatch(updateSenderId(token, senderid?.clientid, senderid?.id, senderId)).then(() => {
            dispatch(getAdminSenderIds(token));
            dispatch(
              openSnackbar({
                open: true,
                message: "Sender ID updated successfully.",
                variant: "alert",
                alert: {
                  color: "success"
                },
                close: false
              })
            );
          });
        } else {
          dispatch(createFunction(senderId))
            .then(() => {
              dispatch(getIds(token, senderid?.clientid));
              dispatch(
                openSnackbar({
                  open: true,
                  message: `${banned ? "Banned Sender ID added successfully." : "Sender ID added successfully."}`,
                  variant: "alert",
                  alert: {
                    color: "success"
                  },
                  close: false
                })
              );
              setStatus({ success: false });
              setSubmitting(false);
            })
            .catch((error) => {
              console.error("ssssacsac", error);
              setStatus({ success: false });
              setSubmitting(false);
              if (error.message === "Invalid sender ID") {
                dispatch(
                  openSnackbar({
                    open: true,
                    message: error.message,
                    variant: "alert",
                    alert: {
                      color: "success"
                    },
                    close: false
                  })
                );
              }
            });
        }
        setIsLoading(false);
        setSubmitting(false);
        onCancel();
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  {
    isLoading && <Loader />;
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {banned ? (
            <DialogTitle>{"Add New Banned Sender ID"}</DialogTitle>
          ) : (
            <DialogTitle>{senderid ? "Edit Sender ID" : "Add New Sender ID"}</DialogTitle>
          )}

          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="senderid-senderid">Sender ID</InputLabel>
                      <TextField
                        fullWidth
                        id="senderid-senderid"
                        values={formik.values.senderid}
                        {...getFieldProps("senderid")}
                        error={Boolean(touched.senderid && errors.senderid)}
                        helperText={touched.senderid && errors.senderid}
                      />
                    </Stack>
                  </Grid>
                  {!banned && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        value={formik.values.isactive}
                        {...getFieldProps("isactive")}
                        control={<Checkbox />}
                        label="Set Active"
                        labelPlacement="end"
                        sx={{ mr: 1 }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                {/* {!isCreating && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton onClick={() => setOpenAlert(true)} size="large" color="error">
                        <Trash variant="Bold" />
                      </IconButton>
                    </Tooltip>
                  )} */}
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button color="error" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    {senderid ? "Edit" : "Add"}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </FormikProvider>
      {/* {!isCreating && <AlertDelete title={senderid.senderid} open={openAlert} handleClose={handleAlertClose} />} */}
    </>
  );
};

AddSenderId.propTypes = {
  senderid: PropTypes.any,
  onCancel: PropTypes.func
};

export default AddSenderId;
