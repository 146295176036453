import "../../../assets/datestyle.css";

import * as Yup from "yup";

import {
  // Autocomplete,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  // FormControl,
  FormControlLabel,
  Grid,
  Box,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Tabs,
  Tab,
  TextField,
  // Tooltip,
  Typography,
  CircularProgress
  // useTheme
} from "@mui/material";
import { Form, Formik } from "formik";
import { addBundle, getBundles, getBundlesByIdNoState, updateBundle } from "store/reducers/setups";
import { useState, useMemo, useEffect } from "react";

import AlertDelete from "components/AlertDelete";
// import { DateRangePicker } from "rsuite";
// import Autocomplete from "themes/overrides/Autocomplete";
// project-imports
// import IconButton from "components/@extended/IconButton";
import Loader from "components/Loader";
import PropTypes from "prop-types";
// assets
// import { Trash } from "iconsax-react";
// import dayjs from "dayjs";
import { dispatch } from "store";
// import { map } from "lodash";
import { openSnackbar } from "store/reducers/snackbar";
import useUserAuthInfo from "hooks/useAuthInfo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MuiColorInput } from "mui-color-input";
import useAdminClientId from "hooks/useAdminClientId";
import { useSelector } from "store";

// ==============================|| FEE - ADD / EDIT ||============================== //

const AddBundle = ({
  b,
  isClient,
  onCancel
  //  users,
}) => {
  const bundle = useMemo(() => b, [b]);
  const { token } = useUserAuthInfo();
  const { targetId } = useAdminClientId();
  const { destinations } = useSelector((state) => state.setups);
  const { services, bundleCategory } = useSelector((state) => state.options);
  const isCreating = !bundle;
  const [frogService, setFrogService] = useState(services[0]);

  const handleFrogServiceChange = (event, newValue) => {
    setFrogService(newValue);
  };
  const [dataDetails, setDataDetails] = useState({});
  const [isAddActive, setIsAddActive] = useState(false);

  const [loading, setIsLoading] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    onCancel();
  };

  const destinationList = (
    isClient
      ? _.filter(_.get(destinations, "content", []), (d) => {
          return d.clientid === null || d.clientid === targetId;
        })
      : _.filter(_.get(destinations, "content", []), (d) => {
          return d.clientid === null;
        })
  ).map((d) => ({
    value: d.id,
    prefix: d.prefixsurfix,
    clientid: d.clientid,
    service: d.service,
    label: d.routecode,
    desc: d.description
  }));

  const handleCheckboxChange = (d) => (event) => {
    const newData = { ...dataDetails };
    if (event.target.checked) {
      newData[d.value] = { id: d.value, label: d.label, frogService: d.service, destinationid: d.value, quantity: 1 };
    } else {
      delete newData[d.value];
    }
    setDataDetails(newData);
  };

  useEffect(() => {
    if (!isCreating) {
      setIsLoading(true);
      getBundlesByIdNoState(token, bundle.id)
        .then((res) => {
          const newData = {};
          _.get(res, "data", []).forEach((d) => {
            newData[d.destinationid] = {
              id: d.destinationid,
              label: destinationList.find((dest) => dest.value === d.destinationid).label,
              quantity: d.quantity,
              frogService: d.service
            };
          });
          setDataDetails(newData);
        })
        .catch(() => {
          onCancel();
          dispatch(
            openSnackbar({
              open: true,
              message: "Can not edit this bundle at this moment. Please try again later.",
              variant: "alert",
              alert: {
                color: "error"
              },
              close: false
            })
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [bundle, token]);

  useEffect(() => {
    if (Object.keys(dataDetails).length > 0) {
      setIsAddActive(true);
    } else {
      setIsAddActive(false);
    }
  }, [dataDetails]);

  const handleQuantityChange = (d) => (event) => {
    const newData = { ...dataDetails };
    newData[d.value] = { ...newData[d.value], quantity: parseInt(event.target.value, 10) || 0 };
    setDataDetails(newData);
  };

  // const [filteredDes, setFilteredDes] = useState(destinationList);

  const schema = Yup.object().shape({
    bgcolor: Yup.string(),
    bundlecategory: Yup.string(),
    // clientid: Yup.number(),
    daysvalid: Yup.number(),
    enddate: Yup.string(),
    invoicedaysvalid: Yup.number(),
    isactive: Yup.boolean(),
    issystembundle: Yup.boolean(),
    name: Yup.string(),
    price: Yup.number(),
    startdate: Yup.string(),
    tax: Yup.number(),
    destinationid: Yup.number(),
    quantity: Yup.number(),
    frogService: Yup.string()
  });

  if (loading) return <Loader />;

  //   const { handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <>
      <DialogTitle>{bundle ? "Edit  Bundle" : "Add New Bundle"}</DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 2.5 }}>
        {loading ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <CircularProgress color="primary" />
            <Typography variant="subtitle1">Loading...</Typography>
          </Stack>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              bgcolor: bundle?.bgcolor || "#ffffff",
              bundlecategory: bundle?.bundlecategory || "SMS",
              daysvalid: bundle?.daysvalid || 0,
              invoicedaysvalid: bundle?.invoicedaysvalid || 0,
              isactive: bundle?.isactive || true,
              issystembundle: isCreating ? false : bundle.issystembundle,
              name: bundle?.name || "",
              price: bundle?.price || 0,
              tax: bundle?.tax || 0,
              startdate: bundle?.startdate || dayjs().format("YYYY-MM-DD"),
              enddate: bundle?.enddate || dayjs().format("YYYY-MM-DD")
            }}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              setIsLoading(true);
              const bundleData = {
                bgcolor: values.bgcolor,
                bundlecategory: values.bundlecategory,
                clientid: isClient ? targetId : null,
                daysvalid: values.daysvalid,
                enddate: values.enddate,
                invoicedaysvalid: values.invoicedaysvalid,
                isactive: values.isactive,
                issystembundle: values.issystembundle,
                name: values.name,
                price: values.price,
                startdate: values.startdate,
                tax: values.tax,
                details: [
                  ...Object.values(dataDetails).map((d) => ({
                    destinationid: d.id,
                    frogService: d.frogService,
                    quantity: d.quantity
                  }))
                ]
              };
              if (bundle) {
                // Updating bundle
                updateBundle(token, bundle.id, bundleData)
                  .then(() => {
                    dispatch(getBundles(token));
                    dispatch(
                      openSnackbar({
                        open: true,
                        message: "Bundle updated successfully.",
                        variant: "alert",
                        alert: {
                          color: "success"
                        },
                        close: false
                      })
                    );
                    setIsLoading(false);
                    setSubmitting(false);
                    onCancel();
                    resetForm();
                  })
                  .catch(() => {
                    setSubmitting(false);
                    setIsLoading(false);
                    dispatch(
                      openSnackbar({
                        open: true,
                        message: "An error occurred updating bundle",
                        variant: "alert",
                        alert: {
                          color: "error"
                        },
                        close: false
                      })
                    );
                  });
              } else {
                //Creating bundle
                addBundle(token, bundleData)
                  .then(() => {
                    dispatch(getBundles(token));
                    dispatch(
                      openSnackbar({
                        open: true,
                        message: "Bundle added successfully.",
                        variant: "alert",
                        alert: {
                          color: "success"
                        },
                        close: true
                      })
                    );
                    setSubmitting(false);
                    setIsLoading(false);
                    onCancel();
                    resetForm();
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    setIsLoading(false);
                    console.error(err);
                    // setErrors({ " });
                    dispatch(
                      openSnackbar({
                        open: true,
                        message: "An error occurred adding bundle",
                        variant: "alert",
                        anchorOrigin: { vertical: "top", horizontal: "right" },

                        alert: {
                          color: "true"
                        },
                        close: true
                      })
                    );
                  });
              }
            }}
          >
            {({ values, handleBlur, handleChange, isSubmitting, resetForm, getFieldProps, setFieldValue }) => {
              return (
                <Form>
                  <Grid container spacing={3} sx={{ padding: "0 10px" }}>
                    {/* Name Field */}
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="bundleDescription">Name</InputLabel>
                        <OutlinedInput
                          fullWidth
                          value={values.name}
                          id="name"
                          name="name"
                          onBlur={handleBlur}
                          // {...getFieldProps("bundleDescription")}
                          onChange={handleChange}
                          // error={Boolean(touched.name && errors.name)}
                        />
                      </Stack>
                      {/* {touched.name && errors.name && (
                    <FormHelperText error id="helper-text-name">
                      {errors.name}
                    </FormHelperText>
                  )} */}
                    </Grid>
                    {/* Amount Field */}
                    <Grid item xs={12} md={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="bundleAmount">Price (GHS)</InputLabel>
                        <TextField
                          fullWidth
                          type="number"
                          id="price"
                          name="price"
                          // {...getFieldProps("bundleAmount")}
                          value={values.price}
                          onChange={handleChange}
                          // error={Boolean(touched.price && errors.price)}
                        />
                        {/* {touched.price && errors.price && (
                      <FormHelperText error id="helper-text-fullname-signup">
                        {errors.price}
                      </FormHelperText>
                    )} */}
                      </Stack>
                    </Grid>
                    {/* Quantity Field */}
                    {/* <Grid item xs={12} md={3}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="bundleAmount">Quantity</InputLabel>
                      <TextField
                        fullWidth
                        type="number"
                        id="quantity"
                        name="quantity"
                        // {...getFieldProps("bundleAmount")}
                        value={values.quantity}
                        onChange={handleChange}
                        // error={Boolean(touched.quantity && errors.quantity)}
                      />
                       {touched.quantity && errors.quantity && (
                      <FormHelperText error id="helper-text-fullname-signup">
                        {errors.quantity}
                      </FormHelperText>
                    )} 
                    </Stack>
                  </Grid> */}
                    {/* Tax Field */}
                    <Grid item xs={12} md={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="tax">Tax</InputLabel>
                        <TextField
                          fullWidth
                          type="number"
                          id="tax"
                          name="tax"
                          // {...getFieldProps("bundleAmount")}
                          value={values.tax}
                          onChange={handleChange}
                          // error={Boolean(touched.tax && errors.tax)}
                        />
                        {/* {touched.tax && errors.tax && (
                      <FormHelperText error id="helper-text-fullname-signup">
                        {errors.tax}
                      </FormHelperText>
                    )} */}
                      </Stack>
                    </Grid>
                    {/* Bundle Valid Field */}
                    <Grid item xs={12} md={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="bundleValid">Bundle Valid day(s)</InputLabel>
                        <TextField
                          fullWidth
                          type="number"
                          id="daysvalid"
                          name="daysvalid"
                          // {...getFieldProps("bundleValid")}
                          value={values.daysvalid}
                          onChange={handleChange}
                          // error={Boolean(touched.daysvalid && errors.daysvalid)}
                        />
                        {/* {touched.daysvalid && errors.daysvalid && (
                      <FormHelperText error id="helper-text-daysvalid">
                        {errors.daysvalid}
                      </FormHelperText>
                    )} */}
                      </Stack>
                    </Grid>
                    {/* Invoice Valid Field */}
                    <Grid item xs={12} md={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="invoicedaysvalid">Invoice Valid day(s):</InputLabel>
                        <TextField
                          fullWidth
                          type="number"
                          name="invoicedaysvalid"
                          id="invoicedaysvalid"
                          value={values.invoicedaysvalid}
                          onChange={handleChange}
                          // {...getFieldProps("invoiceValid")}
                          // error={Boolean(touched.invoicedaysvalid && errors.invoicedaysvalid)}
                        />
                      </Stack>
                    </Grid>
                    {/* Bundle Category Field */}
                    <Grid item xs={12} md={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="bundleCategory">Bundle Category</InputLabel>
                        <Select
                          id="bundlecategory"
                          name="bundlecategory"
                          displayEmpty
                          {...getFieldProps("bundlecategory")}
                          onChange={(event) => setFieldValue("bundlecategory", event.target.value)}
                          input={<OutlinedInput id="select-bundlecategory" placeholder="Sort by" />}
                          renderValue={(selected) => {
                            if (!selected) {
                              return <Typography variant="body2">Select Category</Typography>;
                            }

                            return <Typography variant="subtitle2">{selected}</Typography>;
                          }}
                        >
                          {bundleCategory?.map((option) => (
                            <MenuItem key={option} value={option}>
                              <ListItemText primary={option} />
                            </MenuItem>
                          ))}
                        </Select>
                        {/* {touched.bundlecategory && errors.bundlecategory && (
                      <Typography variant="caption" color="error">
                        {errors.bundlecategory}
                      </Typography>
                    )} */}
                      </Stack>
                    </Grid>
                    {/* Color Field */}
                    <Grid item xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="bgcolor">Card Color</InputLabel>
                        <MuiColorInput
                          isAlphaHidden
                          format="hex"
                          value={values.bgcolor}
                          onChange={(color) => setFieldValue("bgcolor", color)}
                        />
                      </Stack>
                    </Grid>
                    {/* Start Date */}
                    <Grid item xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel id="startdate">Start Date</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name="startdate"
                            minDate={isCreating ? dayjs() : null}
                            value={dayjs(values.startdate)}
                            onChange={(date) => {
                              setFieldValue("startdate", dayjs(date).format("YYYY-MM-DD"));
                            }}
                            // defaultValue={isCreating ? dayjs() : dayjs(bundle.startdate)}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    {/* End Date */}
                    <Grid item xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel id="enddate">End Date</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name="enddate"
                            minDate={isCreating ? dayjs() : null}
                            value={dayjs(values.enddate)}
                            onChange={(date) => {
                              setFieldValue("enddate", dayjs(date).format("YYYY-MM-DD"));
                            }}
                            //defaultValue={isCreating ? dayjs() : dayjs(bundle.enddate)}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    {/* IsActive */}
                    <Grid item xs={12} md={3} alignSelf="end">
                      <Stack spacing={1}>
                        <FormControlLabel
                          name="isactive"
                          checked={values.isactive}
                          {...getFieldProps("isactive")}
                          control={<Checkbox />}
                          label="Set Active"
                          labelPlacement="end"
                        />
                      </Stack>
                    </Grid>
                    {/* IsSystem */}
                    <Grid item xs={12} md={3} alignSelf="end">
                      <Stack spacing={1}>
                        <FormControlLabel
                          name="issystembundle"
                          checked={values.issystembundle}
                          {...getFieldProps("issystembundle")}
                          control={<Checkbox />}
                          label="System Bundle"
                          labelPlacement="end"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} alignSelf="center">
                      <Typography variant="h5">Select Services</Typography>
                    </Grid>
                    {/* Service Tab */}
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={frogService} onChange={handleFrogServiceChange} aria-label="basic tabs example">
                          {services?.map((service, index) => (
                            <Tab key={index} label={service} {...a11yProps(index)} />
                          ))}
                        </Tabs>
                      </Box>
                      {services?.map((service, index) => (
                        <CustomTabPanel
                          style={{
                            marginBottom: "10px"
                          }}
                          key={index}
                          value={frogService}
                          index={index}
                        >
                          <Typography
                            xs={{
                              textAlign: "center"
                            }}
                          >
                            {service} Destinations
                          </Typography>
                          {destinationList
                            .filter((d) => d.service === service)
                            .map((d, index) => (
                              <Grid container key={index}>
                                <Grid item xs={12} md={6}>
                                  <Box>
                                    <FormControlLabel
                                      onChange={handleCheckboxChange(d)}
                                      control={<Checkbox defaultChecked={!!dataDetails[d.value]} />}
                                      label={
                                        <Stack
                                          sx={{
                                            my: 1
                                          }}
                                        >
                                          <Typography>{`(${d.prefix})` + " " + d.label}</Typography>
                                          <Typography>{d.desc}</Typography>
                                        </Stack>
                                      }
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  {dataDetails[d.value] && (
                                    <TextField
                                      sx={{
                                        mb: 1,
                                        mt: 1
                                      }}
                                      fullWidth
                                      label="Quantity"
                                      value={dataDetails[d.value].quantity}
                                      onChange={handleQuantityChange(d)}
                                    />
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  <Divider />
                                </Grid>
                              </Grid>
                            ))}
                        </CustomTabPanel>
                      ))}
                    </Box>
                  </Grid>
                  <DialogActions sx={{ p: 2.5 }}>
                    <Grid container justifyContent="flex-end" alignItems="center">
                      {/* Delete Button */}
                      {/* <Grid item>
                    {!isCreating && (
                      <Tooltip title="Suspend Bundle" placement="top">
                        <IconButton onClick={() => setOpenAlert(true)} size="large" color="error">
                          <Trash variant="Bold" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid> */}

                      {/* <Grid item>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Button color="error" onClick={onCancel}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" disabled={isSubmitting}>
                        {bundle ? "Edit" : "Add"}
                      </Button>
                    </Stack>
                  </Grid> */}
                      <Grid item>
                        <Stack direction="row" justifyContent={"flex-end"} spacing={2} alignItems="center">
                          <Button
                            color="error"
                            onClick={() => {
                              resetForm();
                              onCancel();
                            }}
                          >
                            Cancel
                          </Button>
                          <Button type="submit" variant="contained" disabled={!isAddActive || isSubmitting}>
                            {bundle ? "Edit" : "Add"}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        )}
      </DialogContent>

      {!isCreating && <AlertDelete title={bundle.name} open={openAlert} handleClose={handleAlertClose} />}
    </>
  );
};

AddBundle.propTypes = {
  b: PropTypes.object,
  users: PropTypes.array,
  onCancel: PropTypes.func,
  isClient: PropTypes.bool
};

export default AddBundle;

function CustomTabPanel(props) {
  const { style, children, value, index, ...other } = props;

  return (
    <div
      style={style}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
