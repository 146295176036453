// material-ui
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";

import { PopupTransition } from "components/@extended/Transitions";
import PropTypes from "prop-types";
import { Stack } from "@mui/system";

// import { useTheme } from "@mui/material/styles";

// assets

// import { useState } from "react";

// project-imports

// ==============================|| Invoice - PREVIEW ||============================== //

export default function ViewBundle({ bundle, open, onClose }) {
  //   const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down("md"));
  // const [openAlert, setOpenAlert] = useState(false);

  //   const [add, setAdd] = useState(false);

  // const handleAdd = () => {
  //   setAdd(!add);
  // };

  // const handleClose = () => {
  //   setOpenAlert(!openAlert);
  //   onClose();
  // };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        TransitionComponent={PopupTransition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{ "& .MuiDialog-paper": { p: 0 }, transition: "transform 225ms" }}
      >
        <Box id="PopupPrint" sx={{ px: { xs: 2, sm: 3, md: 5 }, py: 1 }}>
          <DialogTitle sx={{ px: 0 }}>
            <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h5">{bundle.name}</Typography>
              <Typography variant="subtitle1" color="cadetsecondary">
                {bundle.isactive ? (
                  <Chip color="success" label="active" size="small" variant="light" />
                ) : (
                  <Chip color="error" label="inactive" size="small" variant="light" />
                )}
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent dividers sx={{ px: 0 }}>
            <Stack
              spacing={1.25}
              sx={{
                marginBottom: "4rem"
              }}
            >
              <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="body1">Bundle Category</Typography>
                <Typography variant="subtitle1" color="cadetsecondary">
                  {bundle.bundlecategory}
                </Typography>
              </Stack>
              <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="body1">Price</Typography>
                <Typography variant="subtitle1" color="cadetsecondary">
                  {bundle.price} GHS
                </Typography>
              </Stack>
              <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="body1">Tax</Typography>
                <Typography variant="subtitle1" color="cadetsecondary">
                  {bundle.tax}
                </Typography>
              </Stack>
              <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="body1">Valid For</Typography>
                <Typography variant="subtitle1" color="cadetsecondary">
                  {bundle.daysvalid} day(s)
                </Typography>
              </Stack>
              <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="body1">Invoice Valid For</Typography>
                <Typography variant="subtitle1" color="cadetsecondary">
                  {bundle.invoicedaysvalid} day(s)
                </Typography>
              </Stack>
              <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="body1">System Bundle</Typography>
                <Typography variant="subtitle1" color="cadetsecondary">
                  {bundle.issystembundle ? (
                    <Chip color="success" label="active" size="small" variant="light" />
                  ) : (
                    <Chip color="error" label="inactive" size="small" variant="light" />
                  )}
                </Typography>
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="end">
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button variant="contained" color="primary" onClick={onClose}>
                    Close
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

ViewBundle.propTypes = {
  bundle: PropTypes.shape({
    bundlecategory: PropTypes.any,
    category: PropTypes.any,
    name: PropTypes.any
  }),
  customer: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
