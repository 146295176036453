import { Container, Dialog, Grid } from "@mui/material";
import { Monero, MoneyRecive } from "iconsax-react";

import ClientCTA from "./ClientCTA";
import Template from "./template";
import useAdminClientId from "hooks/useAdminClientId";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import GenerateInvoice from "./generateInvoice";
import { PopupTransition } from "components/@extended/Transitions";
import { getClientSubClientsNoState } from "store/reducers/users";
import { dispatch } from "store";
import { openSnackbar } from "store/reducers/snackbar";
import useUserAuthInfo from "hooks/useAuthInfo";

// import { useParams } from "react-router";

// import userStats1 from "assets/images/widget/userstats1.svg";
// import userStats2 from "assets/images/widget/userstats2.svg";
// import userStats3 from "assets/images/widget/userstats3.svg";
// import userStats4 from "assets/images/widget/userstats4.svg";

const Credits = () => {
  const theme = useTheme();
  const { token } = useUserAuthInfo();
  const { targetId, client } = useAdminClientId();
  // console.log("🚀 ~ Credits ~ targetId:", targetId);

  // const downMd = useMediaQuery(theme.breakpoints.down("md"));

  const [openDialog, setOpenDialog] = useState(false);
  const [subClients, setSubClients] = useState([]);

  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
  };

  //Get subclients
  useEffect(() => {
    if (targetId) {
      getClientSubClientsNoState(token, targetId)
        .then((res) => {
          setSubClients(_.get(res, "data.content", []));
        })
        .catch(() => {
          dispatch(
            openSnackbar({
              open: true,
              message: "Could not fetch sub clients. Please try again.",
              variant: "alert",
              alert: {
                color: "error"
              },
              close: true
            })
          );
        });
    }
  }, [targetId, token]);

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ClientCTA title="Credits" showDo user={client ? client?.name : "..."} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <Template
                  isLink={false}
                  clickFn={() => {
                    setOpenDialog(true);
                  }}
                  // link={`/admin/clients/credits/balance/${targetId}`}
                  primary="Generate Invoice"
                  color={theme.palette.primary.main}
                  iconPrimary={Monero}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <Template
                  link={`/admin/clients/credits/balance/${targetId}`}
                  primary="Balance"
                  color={theme.palette.primary.main}
                  iconPrimary={Monero}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <Template
                  link={`/admin/clients/credits/bundles/${targetId}`}
                  primary="Bundles"
                  color={theme.palette.primary.main}
                  iconPrimary={Monero}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <Template
                  link={`/admin/clients/credits/invoices/${targetId}`}
                  primary="Invoices"
                  color={theme.palette.primary.main}
                  iconPrimary={MoneyRecive}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        maxWidth="sm"
        TransitionComponent={PopupTransition}
        keepMounted
        onClose={handleCloseDialog}
        open={openDialog}
        sx={{ "& .MuiDialog-paper": { p: 0 }, transition: "transform 225ms" }}
        aria-describedby="alert-dialog-slide-description"
      >
        <GenerateInvoice subClients={subClients} client={client} onCancel={handleCloseDialog} targetId={targetId} />
      </Dialog>
    </>
  );
};
export default Credits;
