import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";

// assets
// import BackLeft from "assets/images/profile/UserProfileBackLeft";
import BackRight from "assets/images/profile/UserProfileBackRight";
// project-imports
import MainCard from "components/MainCard";
import PropTypes from "prop-types";
import { ThemeMode } from "config";
// import { useNavigate } from "react-router";
// material-ui
import { useTheme } from "@mui/material/styles";

// ==============================|| USER PROFILE - TOP CARD ||============================== //

const ClientCTA = ({ user, title = "", showDo = false }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MainCard
      border={false}
      sx={{ bgcolor: theme.palette.mode === ThemeMode.DARK ? "primary.700" : "primary.lighter", position: "relative" }}
    >
      {/* <Box sx={{ position: "absolute", bottom: "-7px", left: 0, zIndex: 1 }}>
        <BackLeft />
      </Box> */}
      <Grid container justifyContent="space-between" alignItems="center" sx={{ position: "relative", zIndex: 5 }}>
        <Grid item>
          <Stack direction="row" spacing={matchDownSM ? 1 : 2} alignItems="center">
            <Stack spacing={0.75}>
              <Typography variant="h4">
                Managing {user}
                {showDo ? "" : "'s "} {title}{" "}
              </Typography>
              {showDo && (
                <Typography variant="body1" color="secondary">
                  What would you want to do for client {user}?
                </Typography>
              )}
            </Stack>
          </Stack>
        </Grid>
        {/* Space out these btns */}
        <Grid item md={6} xs={12} sx={{ px: matchDownSM ? 2 : 3, pb: matchDownSM ? 2 : 0 }}>
          {/* <Grid container justifyContent="space-between">
            <Button
              onClick={() => {
                navigate(`/bulk-messaging/send-message`);
              }}
              variant="contained"
              fullWidth
              sx={{ flex: 1 }}
            >
              Send Message
            </Button>
            <Button
              onClick={() => {
                navigate(`/address-book/bulk-imports`);
              }}
              variant="contained"
              fullWidth
              sx={{ flex: 1, mx: 1 }}
            >
              Import Contacts
            </Button>
            <Button
              onClick={() => {
                //TODO: Navigate to purchase bundle page
                navigate(`/credits/bundles`);
              }}
              variant="contained"
              fullWidth
              sx={{ flex: 1 }}
            >
              Purchase Bundle
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
      <Box sx={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}>
        <BackRight />
      </Box>
    </MainCard>
  );
};

ClientCTA.propTypes = {
  focusInput: PropTypes.func
};

export default ClientCTA;
